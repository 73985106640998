import React, { useState } from "react";

const Accordion = ({ title, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="py-5 my-7 md:py-3 bg-blue-950 text-5xl md:text-sm text-white w-full lg:w-96">
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className="flex justify-between w-full"
      >
        <span className="mx-5">{title}</span>
        {/* {accordionOpen ? <span>-</span> : <span>+</span>} */}
        <div className="right-0 ">

        <svg
          className="fill-white shrink-0 mx-8 my-1"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
          >
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center transition duration-200 ease-out ${
              accordionOpen && "!rotate-180"
            }`}
            />
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center rotate-90 transition duration-200 ease-out ${
              accordionOpen && "!rotate-180"
            }`}
            />
        </svg>
            </div>
      </button>
      <div
        className={`grid overflow-hidden border-[1px] border-blue-950 transition-all duration-300  ease-in-out text-blue-950 bg-white text-sm ${
          accordionOpen
            ? "grid-rows-[1fr] opacity-100 h-max"
            : "grid-rows-[0fr] opacity-0 h-0"
        }`}
      >
        <div className="overflow-hidden text-4xl  md:text-sm px-5 py-7">{answer}</div>
      </div>
    </div>
  );
};

export default Accordion;