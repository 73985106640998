import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../components/sidebar/Sidebar'
import Navbar from '../components/navbar/Navbar'
import Modal from '../components/Modal'

const AdminCareer = () => {
    const [career,setCareer]=useState([])
    const [loading,setLoading]=useState(true)
    const [pdfFile, setPdfFile] = useState(null);
    // get all career applicants

    const getAllCareer=async()=>{
        try{
            setLoading(true)
            const res=await fetch('https://api.bgsthimiri.com/career/',{
                method:'GET',
                headers:{'Content-type':'application/json'}
            })
            if(res.ok){
                const carrerarr=await res.json()
                setCareer(carrerarr)
                console.log(career)
            }else{
                alert('failed to fetch career data')
            }
        }
        catch(err){
            console.log(err)
        }finally{
          setLoading(false)
        }

}

useEffect(()=>{
    getAllCareer()
},[])


  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCareer, setSelectedCareer] = useState(null);

  const openModal = (career) => {
    setSelectedCareer(career);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedCareer(null);
    setModalOpen(false);
  };
  const openFile=(fileName)=>{
    window.open(`https://api.bgsthimiri.com/files/${fileName}`, "_blank", "noreferrer");
  }
  const deleteCandidate=async(id)=>{
    console.log(id)
    const res=await fetch('https://api.bgsthimiri.com/career/'+id,{
      method:'DELETE',
      headers:{'Content-type':'application/json'},
    })
    if(res.ok){
      alert('deleted')
  }else{
      alert('failed to delete')
  }
  getAllCareer()
  }

  return (
    
    <div className='w-full flex'>
        <Sidebar/>
        <div className='flex-[6]'>
            <Navbar/>
            {loading ? (
        <div className="loading-overlay">
          <div className="loader"></div>
        </div>

    ) : (<>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {career.map((c1, index) => (
        <div key={index} className="p-4 border border-gray-200 rounded-lg">
          <div onClick={() => openModal(c1)} className="cursor-pointer">
            {/* {c1.student.firstname} */}
          </div>
          <button onClick={()=>openFile(c1.resume)}>Resume</button>
          <button onClick={()=>deleteCandidate(c1._id)}>Delete</button>
        </div>
      ))}
      <Modal isOpen={modalOpen} onClose={closeModal} career={selectedCareer} />
      
    </div>
</>
    )}
        </div>

    </div>
  )
}

export default AdminCareer