/*
  This example requires some changes to your config:
  
  
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/divs'),
    ],
  }

  
  
*/
import i1 from '../assets/images/career.jpeg'
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import Accordion from '../components/Accordion'
import { career } from '../components/career'
import logo from '../assets/images/logo.png'
import Slider from '../components/Slider'
import { useState } from 'react'
import Footer from '../components/Footer'
import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function Career() {
  useEffect(() => {
    AOS.init();
  }, []);
  const careerQuick=[
    {
      title:"Career",
      link:"career"
    }
  ]
  
  const [student,setStudent]=useState({
    firstname:'',
    lastname:'',
    dob:'',
    age:'',
    gender:'',
    email:'',
    experience:'',
    prevemployer:'',
    address:'',
    city:'',
    region:'',
    postal:'',
    position:'',
    preferredcity:'',
    howheard:'',
  })
  const [file,setFile]=useState(null)
  const handleChange = (event) => {
    const { name, value,type } = event.target;
    setStudent({
      ...student,
      [name]: type === 'file' ? event.target.files[0] : value,
    });
  };

  
  const handleSubmit=async()=>{
    const formData = new FormData();
    formData.append("student", JSON.stringify({student}));
    formData.append("file", file);
    const res=await fetch('https://api.bgsthimiri.com/career/',{
      method:'POST',
      headers: {
      Accept: 'application/json',
      "Access-Control-Allow-Origin": "*"},  
      body:formData
    })
    if(res.ok){
      alert('submitted')
    }else{
      alert('something wrong')
    }
  }
  return (
    <div className="font-serif w-[1200px] md:w-full ">

<Slider images={[i1]} menuBackgroundImage={logo} title={"CAREER"} interval={5000} quicklinks={careerQuick} />
        <div id='career' className="lg:flex my-16">

        
    <div className="mx-16 lg:w-[60%] border-[1px] border-yellow-500 p-5" data-aos="fade-up" data-aos-duration="1200">
      <div className="space-y-12">
        

        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-6xl md:text-base font-semibold md:leading-7 text-gray-900">Personal Indivation</h2>
          <p className="mt-1 text-4xl md:text-sm md:leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="mt-16 md:mt-0 sm:col-span-3">
              <label htmlFor="first-name" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                First name
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={handleChange}
                value={student.firstname}
                  type="text"
                  name="firstname"
                  id="first-name"
                  autoComplete="given-name"
                 className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>

            <div className="mt-16 md:mt-0 sm:col-span-3">
              <label htmlFor="last-name" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                Last name
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={handleChange}
                value={student.lastname}
                  type="text"
                  name="lastname"
                  id="last-name"
                  autoComplete="family-name"
                  className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>
            <div className="mt-16 md:mt-0 sm:col-span-3">
              <label htmlFor="dob" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                Date of Birth
              </label>
              <div className="mt-10 md:mt-2">
              <input
              onChange={handleChange}
              value={student.dob}
                  type="date"
                  name="dob"
                  className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  // value={studentData.dateOfBirth}
                  // onChange={handleChange}
                />
              </div>
            </div>
            <div className="mt-16 md:mt-0 sm:col-span-3">
              <label htmlFor="age" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                Age
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={handleChange}
                value={student.age}
                  type="text"
                  name="age"
                  id="age"
                  autoComplete="family-name"
                  className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>
            <div className="mt-16 md:mt-0 sm:col-span-2">
              <label htmlFor="gender" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                Gender
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={handleChange}
                value={student.gender}
                  type="text"
                  name="gender"
                  id="gender"
                  autoComplete="family-name"
                  className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>

            <div className="mt-16 md:mt-0 sm:col-span-4">
              <label htmlFor="email" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                Email address
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={handleChange}
                value={student.email}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>

            

            <div className="col-span-full">
              <label htmlFor="street-address" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                Street address
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={handleChange}
                value={student.address}
                  type="text"
                  name="address"
                  id="address"
                  autoComplete="address"
                  className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>

            <div className="mt-16 md:mt-0 sm:col-span-2 sm:col-start-1">
              <label htmlFor="city" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                City
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={handleChange}
                value={student.city}
                  type="text"
                  name="city"
                  id="city"
                  autoComplete="address-level2"
                  className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>

            <div className="mt-16 md:mt-0 sm:col-span-2">
              <label htmlFor="region" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                State / Province
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={handleChange}
                value={student.region}
                  type="text"
                  name="region"
                  id="region"
                  autoComplete="address-level1"
                  className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>

            <div className="mt-16 md:mt-0 sm:col-span-2">
              <label htmlFor="postal-code" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                ZIP / Postal code
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={handleChange}
                value={student.postal}
                  type="text"
                  name="postal"
                  id="postal"
                  autoComplete="postal"
                  className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>
            <div className="mt-16 md:mt-0 sm:col-span-2">
              <label htmlFor="experience" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                Experience
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={handleChange}
                value={student.experience}
                  type="text"
                  name="experience"
                  id="experience"
                  autoComplete="experience"
                  className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>
            <div className="mt-16 md:mt-0 sm:col-span-4">
              <label htmlFor="prevemployer" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                Previous Current Employer
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={handleChange}
                value={student.prevemployer}
                  type="text"
                  name="prevemployer"
                  id="prevemployer" 
                  autoComplete="prevemployer"
                  className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>
            <div className="mt-16 md:mt-0 sm:col-span-3">
              <label htmlFor="position" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                Position
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={handleChange}
                value={student.position}
                  type="text"
                  name="position"
                  id="position" 
                  autoComplete="position"
                  className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>
            <div className="mt-16 md:mt-0 sm:col-span-3">
              <label htmlFor="preferredcity" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                Preferred  City
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={handleChange}
                value={student.preferredcity}
                  type="text"
                  name="preferredcity"
                  id="preferredcity" 
                  autoComplete="preferredcity"
                  className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>
            <div className="mt-16 md:mt-0 sm:col-span-6">
              <label htmlFor="howheard" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                How did you hear about us
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={handleChange}
                value={student.howheard}
                  type="text"
                  name="howheard"
                  id="howheard" 
                  autoComplete="howheard"
                  className="shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>
            <div className="mt-16 md:mt-0 sm:col-span-6">
              <label htmlFor="resume" className="block   font-medium leading-6 text-blue-950 text-6xl md:text-2xl">
                Resume(only pdf)
              </label>
              <div className="mt-10 md:mt-2">
                <input
                onChange={(e)=>setFile(e.target.files[0])}
                
                  type="file"
                  name="pdf"
                  id="pdf"
                  accept='application/pdf' 
                  autoComplete="pdf"
                  className="text-5xl md:text-sm shadow appearance-none border-[1px] border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button type="button" className="text-5xl md:text-sm font-semibold leading-6 text-gray-900">
          Cancel
        </button>
        <button
        onClick={handleSubmit}
          
          className="rounded-md bg-indigo-600 md:px-3 md:py-2 md:text-sm text-5xl py-4 px-4 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
          Save
        </button>
      </div>
    </div>
    <div className="mx-16 " data-aos="fade-up" data-aos-duration="1200">
        {career.map(m1=>(

            <Accordion title={m1.topic} answer={m1.answer}/>
        ))}
    </div>
    </div>
    <Footer/>
            </div>
  )
}