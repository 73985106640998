import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';
import { RiMenuUnfoldFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa6";
import { IoMdArrowDropdown } from "react-icons/io";
import gsap from 'gsap';
import { LuMenu } from "react-icons/lu";
const Slider = ({ images,title,menuBackgroundImage,quicklinks, interval }) => {
  const textRefs = {
    welcomeText: useRef(null),
    schoolName: useRef(null), 
    quickLinks: useRef([]),
    titleText: useRef(null)
  };
  useEffect(() => {
    // Function to split text into individual characters
    const splitText = (element) => {
      const text = element.innerText;
      element.innerHTML = text.split('').map(char => `<span class="char">${char}</span>`).join('');
      return element.querySelectorAll('.char');
    };

    // Jump in animation
    const jumpInText = (chars) => {
      gsap.fromTo(chars, {
        y: 50,
        opacity: 0,
      }, {
        y: 0,
        opacity: 1,
        duration: 2,
        ease: 'bounce.out',
        stagger: 0.1,
      });
    };

    // Apply jump in animation to each text element
    const welcomeChars = splitText(textRefs.welcomeText.current);
    jumpInText(welcomeChars);

    const schoolNameChars = splitText(textRefs.schoolName.current);
    jumpInText(schoolNameChars);

    const titleChars = splitText(textRefs.titleText.current);
    jumpInText(titleChars);

    textRefs.quickLinks.current.forEach(ref => {
      const linkChars = splitText(ref);
      jumpInText(linkChars);
    });

  }, []);


  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);
    return () => clearInterval(intervalId);
  }, [images, interval]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };


  return (
    <div className="relative w-[1200px] h-[2600px] md:h-screen md:w-full  overflow-hidden font-serif tracking-wider">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'
          } `}
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: index === currentImageIndex ? 1 : 0,
          }}
        >
         
          <div className="fixed top-0 left-0 p-4 ">
            <LuMenu className=" text-9xl md:text-4xl border-[1px] border-green-600 text-black bg-white  cursor-pointer rounded-lg" onClick={toggleMenu} />
          </div>

            
            <div className="flex flex-col w-fit ml-[50%]   text-3xl md:text-sm md:ml-[80%] lg:ml-[85%] ">
            <div className="border-2  mr-5 md:h-fit bg-white p-3 rounded-md ">
              <img src={menuBackgroundImage} className="w-80 md:w-32 mx-auto"/>
              <div className="text-center tracking-tight mt-5 text-5xl md:text-sm  font-semibold "  ref={textRefs.schoolName}>BHARATHIDHASANAR<br></br> SCHOOL<br></br>THIMIRI</div>
            </div>
          {quicklinks.map((m1,index)=>(
            <div className="mt-10 mx-auto md:mx-5 md:mt-5 md:mb-2"><button className="button w-[500px]  hover:-translate-y-1 duration-500 border-yellow-400 text-5xl md:text-sm  border-[1px] bg-white py-5 md:px-2 md:w-40 md:p-2 rounded-lg"><ScrollLink 
            activeClass="active" 
            to={m1.link} 
            spy={true} 
            smooth={true} 
            offset={-10} 
            duration={3000} 
            // onSetActive={handleSetActive}
          >
            <div ref={el => textRefs.quickLinks.current[index] = el}>
          {m1.title}

            </div>
          
          </ScrollLink></button></div>
          ))}
          </div>
          <div className="absolute mt-36 md:mt-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
            <div className="md:text-xl text-5xl mb-10 md:mb-3" ref={textRefs.welcomeText}>Welcome to</div>
            <div className="md:text-5xl text-7xl font-bold drop-shadow-[2px_5px_black] text-white" ref={textRefs.titleText}>{title}</div>
            <div className="text-center mx-auto mt-16 md:mt-10 border-2 w-fit rounded-full p-2"><IoMdArrowDropdown className="text-6xl md:text-3xl"/></div>
          </div>
        </div>
      ))}
       <div className={`fixed top-0 left-0 h-[2700px] md:w-full md:h-full bg-blue-950 z-10 transition-all duration-300 transform ${menuOpen ? 'translate-x-0' : '-translate-x-full'
          }`}  style={{
            backgroundImage: `linear-gradient(rgba(23,37,84,0.8),rgba(23,37,84,0.8)), url(${menuBackgroundImage})`,
            backgroundRepeat:'no-repeat',
            backgroundSize:'50%',
            backgroundPositionX:'450px',
            backgroundPositionY:'50%'
          }}>
            <div className="flex justify-end p-4 z-50">
              <IoMdClose className=" text-9xl md:text-3xl border-[1px] border-green-600 text-black bg-white  cursor-pointer" onClick={toggleMenu} />
            </div>
            <div className="flex flex-col   h-full mx-36 ">
              <div className="flex -mx-16 text-5xl md:text-xl">
                <div className="mx-10 md:mx-5"><button className="  hover:-translate-y-1 duration-500 border-green-600  border-[1px] px-10 md:px-5 text-white p-7 md:p-2 hover:bg-green-600 rounded-lg"><Link to='/career'>Careers  →</Link></button></div>
                <div><button className="  hover:-translate-y-1 duration-500 border-green-600  border-[1px] px-10 md:px-5 text-white p-7 md:p-2 hover:bg-green-600 rounded-lg"><Link to='/admission'>Admission →</Link></button></div>
                </div>
                <div className=" my-16">
              <div className='text-7xl md:text-xl'>
              <div className=" my-16 md:my-5"><Link to="/" className="  py-2 text-white uppercase ">Home</Link></div>
              <div className=" my-16 md:my-5"><Link to="/about" className="  py-2 text-white uppercase">About Us</Link></div>
              <div className=" my-16 md:my-5"><Link to="/academics" className="  py-2 text-white uppercase">Academics</Link></div>
              <div className=" my-16 md:my-5"><Link to="/facilities" className="  py-2 text-white uppercase">Facilities</Link></div>
              <div className=" my-16 md:my-5"><Link to="/achievements" className="  py-2 text-white uppercase">Achievements</Link></div>
              <div className=" my-16 md:my-5"><Link to="/gallery" className="  py-2 text-white uppercase">Gallery</Link></div>
              <div className=" my-16 md:my-5"><Link to="/contact" className="  py-2 text-white uppercase">Contact Us</Link></div>
              <div className=" my-16 md:my-5"><Link to="/public-disclosure" className="  py-2 text-white uppercase">Public Disclosure</Link></div>
              </div>
              <div className="flex my-32 md:my-16 ">
                  <div className="mr-10 md:mr-3"><FaFacebook className="text-7xl md:text-2xl text-[#f42f54] "/></div>
                  <div className="mr-10 md:mr-3"><FaInstagram className="text-7xl md:text-2xl text-[#f42f54]"/></div>
                  <div className="mr-10 md:mr-3"><FaTwitter className="text-7xl md:text-2xl text-[#f42f54]"/></div>
                  <div className="mr-10 md:mr-3"><FaLinkedin className="text-7xl md:text-2xl text-[#f42f54]"/></div>
                </div>
                </div>
            </div>
          </div>
    </div>
  );
};

export default Slider;
