import React, { useState, useEffect } from 'react'
import Slider from '../components/Slider'
import i1 from '../assets/images/bh1.jpeg'
import aboutus from '../assets/images/aboutus.jpeg'
import logo from '../assets/images/logo.png'
import Underline from '../components/Underline'
import { managementarr } from '../components/managementarray'
import empty from '../assets/images/profile.png'
import Faculty from '../components/Faculty'
import grp from '../assets/images/grp.jpeg'
import principal from '../assets/images/principal.jpeg'
import Parallax from '../components/Parallax'
import Aos from 'aos'
import 'aos/dist/aos.css';
import Footer from '../components/Footer'
const About = () => {

  const [story, setStory] = useState(managementarr[0].Stories)
  const [image, setImage] = useState(managementarr[0].Image)
  const [name, setName] = useState(managementarr[0].Name)
  const [designation, setDesignation] = useState(managementarr[0].Designation)
  const [studies, setStudies] = useState(managementarr[0].Studies)
  useEffect(() => {
    Aos.init()
  }, [])
  const images = [i1]
  const aboutQuick = [
    {
      title: "About Us",
      link: "school",
    },
    {
      title: "Principal",
      link: "principal",
    },


  ]
  return (
    <div className='w-[1200px] md:w-full'>
      <Slider menuBackgroundImage={logo} title={"ABOUT US"} images={images} quicklinks={aboutQuick} interval={5000} />
      <div className='bg-gray-200 shadow-2xl md:h-full mt-10 ' id="founder"  data-aos="fade-up" data-aos-duration="1000">
        <div className="text-9xl md:text-5xl text-blue-950 text-center pt-16  font-bold" data-aos="zoom-in" data-aos-duration="1000">
          ABOUT US
          <Underline />
        </div>
        <div id='school' className="lg:flex mx-10 lg:mx-32 py-16">
          <div className="text-justify text-5xl leading-normal md:text-xl lg:text-[17px] mr-10 "  data-aos="fade-right" data-aos-duration="1000">
            Welcome to Bharathidhasanar Public School, nestled in the heart of Thimiri. Our school is dedicated to providing a nurturing and inclusive environment where every student is empowered to achieve their full potential. With a focus on holistic development, we offer a balanced curriculum that integrates rigorous academics, vibrant arts, dynamic athletics, and essential social-emotional learning.

            At Bharathidhasanar Public School, we celebrate diversity and prioritize equity, ensuring that each student feels valued and respected. Our dedicated educators employ innovative teaching practices tailored to diverse learning styles, fostering critical thinking, problem-solving, and creative expression among our students.

            With a commitment to excellence and collaboration, we prepare our students to thrive in a rapidly changing world. By instilling values of integrity, empathy, and leadership, we empower our students to make positive contributions to their communities and become responsible global citizens.
            <div className='mt-16'><button className="border-2 p-2 px-10 md:px-5  font-bold hover:-translate-y-1 duration-500  border-yellow-400 bg-white text-black rounded  hover:bg-yellow-400 hover:text-white  ">Learn More </button>
            </div>
          </div>
          <div  data-aos="zoom-in" data-aos-duration="1000">
            <img className='md:w-[50%] mx-auto my-32 md:my-10 lg:my-0 lg:w-[4000px] rounded-lg' src={aboutus} />
          </div>
        </div>
      </div>
      {/* <div id="management" className='h-[3400px] md:h-full'>
        <div className="text-9xl md:text-5xl text-blue-950 text-center pt-16  font-bold">
        MANAGEMENT
        <Underline/>
      </div>
      <div className="md:flex mx-10 lg:mx-32 my-16">
            <div className='grid grid-cols-2 md:block' data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                {managementarr.map(m1=>(

                    <div className="flex my-5 shadow-lg  p-2 md:w-96 md:h-32 cursor-pointer hover:bg-blue-100 duration-500" onClick={()=>{
                        setName(m1.Name)
                        setStudies(m1.Studies)
                        setDesignation(m1.Designation)
                        setStory(m1.Stories)
                        setImage(m1.Image)

                    }}>
                <div className="my-auto">

                   <div className="">
                       <img className="w-32 md:w-20 md:h-20" src={m1.Image}/> 
                    </div> 
                </div>
                <div className="ml-5 my-auto">
                    <div className="text-4xl md:text-2xl">{m1.Name}</div>
                    <div className="text-2xl md:text-sm text-gray-500">{m1.Designation}</div>
                    <div className="text-2xl md:text-sm text-gray-500">{m1.Studies}</div>
                </div>
                </div> 
            ))}
            </div>
            <div data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" className="shadow-lg my-5 lg:ml-32 w-full border-blue-950">
                <div className=" p-5">
                    <div>
                        <img className="rounded-2xl w-[1000px] md:w-[300px]" src={image}/>
                    </div>
                    <div className="text-blue-950">

                    <div className="font-bold mt-10 md:mt-2 text-7xl md:text-2xl">{name}</div>
                    <div className="font-semibold mt-2 md:mt-0 text-5xl md:text-sm">{designation}</div>
                    <div className="font-semibold text-5xl mt-2 md:mt-0 md:text-sm">{studies}</div>
                    </div>
                    <div className="text-justify text-4xl md:text-lg leading-normal mt-10">{story}</div>
                </div>
            </div>
        </div>
        </div>
        <div className='bg-gray-200 shadow-2xl py-36 md:py-0 h-[2400px] md:h-full' id="correspondent">
        <div className="text-9xl md:text-5xl text-blue-950 text-center pt-16  font-bold">
        CORRESPONDENT
        <Underline/>
      </div>
      <div className="md:flex mx-10 lg:mx-32 py-16">
        <div>
            <img className='h-full' src={i1}/>
        </div>
        <div className="text-justify  text-5xl leading-normal mt-32 md:mt-0 md:text-xl lg:text-lg md:ml-10  ">
        Bharathidasanar Group of Schools stands as a testament to the visionary leadership of Late Sri.P.Sekar and his wife Late Smt. Lakshmi Amma, he esteemed founders. Their unwavering commitment to education laid the foundation for his institution, fostering an environment of learning and growth. Today under the capable chairmanship of Sri. S. Sundar, the legacy continues, guiding the school towards new heights of excellence in education and holistic development
        </div>
      </div>
        </div> */}
      <div className='h-[350vh] md:h-full'>
        <Parallax image={grp} gradient="rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)">
          <div>
            <h2 className='text-white mx-32 text-9xl md:text-5xl leading-normal italic'>
              “We Are A Group Of Schools. Know About Our Management And Our Expertise”
            </h2>
            <button className='border-2 p-3 ml-[50%] md:text-2xl text-7xl mt-32 md:mt-10 hover:-translate-y-1 duration-500 border-yellow-400 border-yellow-400 bg-white text-black rounded  hover:bg-yellow-400 hover:text-white  '>Explore Us</button>
          </div>
        </Parallax>
      </div>
      <div id='principal' className=' md:h-full py-32 md:py-0'>
        <div className="text-9xl md:text-5xl text-blue-950 text-center pt-16  font-bold"  data-aos="zoom-in" data-aos-duration="1000">
          PRINCIPAL
          <Underline />
        </div>
        <div className="md:flex mx-10 lg:mx-32 my-16 items-center">
          <div className="text-justify leading-normal py-32 md:py-0  text-5xl md:text-xl lg:text-[17px] mr-10"  data-aos="fade-right" data-aos-duration="1000">


            Meet Mr. P. Gopakumar, the esteemed leader guiding Bharathidhasanar Public School, Thimiri with dedication and vision. With a profound commitment to educational excellence and holistic student development, Mr. P. Gopakumar creates a nurturing environment where every student is encouraged to excel academically, socially, and emotionally. With his extensive experience and innovative approach, Mr. P. Gopakumar fosters a culture of collaboration, creativity, and inclusivity throughout the school community. Passionate about empowering students to become critical thinkers and compassionate leaders, Mr. P. Gopakumar is dedicated to preparing them not only for academic success but also for responsible citizenship and lifelong learning. Under [his/her] leadership, Bharathidhasanar Public School thrives as a place where students are inspired to reach their full potential and contribute positively to society.
          </div>
          <div  data-aos="zoom-in" data-aos-duration="1000"> 
            <img className='h-full w-full md:w-[3000px] rounded-full ' src={principal} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default About