const curriculumarr=[
   
    {
        topic:'PRE-PRIMARY',
        content:`<div>
        <div>ENGLISH</div>
        <div>TAMIL</div>
        <div>HINDI</div>
        <div>EVS</div>
        <div>MATHEMATICS</div>
        </div>`,
    },
    {
        topic:'PRIMARY TO HIGH SCHOOL',
        content:`<div>
        <div>ENGLISH</div>
        <div>TAMIL</div>
        <div>HINDI</div>
        <div>SCIENCE</div>
        <div>MATHEMATICS</div>
        <div>SOCIAL SCIENCE</div>
        <div>COMPUTER SCIENCE</div>
        <div>ROBOTICS & ELT</div>
        </div>`,
    },
    {
        topic:'',
        content:'',
    },
    {
        topic:'',
        content:`<div>COURSES OFFERED FOR HIGHER SECONDARY</div>`,
    },
    {
        topic:'',
        content:`<div>TAMILNADU STATE BOARD</div>`,
    },
    {
        topic:'1',
        content:`<div>
        <div>Physics</div>
        <div>Chemistry</div>
        <div>Biology</div>
        <div>Mathematics</div>
        </div>`,
    },
    {
        topic:'2',
        content:`<div>
        <div>Physics</div>
        <div>Chemistry</div>
        <div>Computer Science</div>
        <div>Mathematics</div>
        </div>`,
    },
    {
        topic:'3',
        content:`<div>
        <div>Physics</div>
        <div>Chemistry</div>
        <div>Botany</div>
        <div>Zoology</div>
        </div>`,
    },
    {
        topic:'4',
        content:`<div>
        <div>Accountancy</div>
        <div>Commerce</div>
        <div>Economics</div>
        <div>Computer Applications</div>
        </div>`,
    },
    {
        topic:'5',
        content:`<div>
        <div>Accountancy</div>
        <div>Commerce</div>
        <div>Economics</div>
        <div>Business Mathematics</div>
        </div>`,
    },
    {
        topic:'',
        content:`<div>CBSE</div>`,
    },
    {
        topic:'1',
        content:`<div>
        <div>Physics</div>
        <div>Chemistry</div>
        <div>Biology</div>
        <div>Mathematics</div>
        </div>`,
    },
    {
        topic:'2',
        content:`<div>
        <div>Physics</div>
        <div>Chemistry</div>
        <div>Computer Science</div>
        <div>Mathematics</div>
        </div>`,
    },
    {
        topic:'3',
        content:`<div>
        <div>Physics</div>
        <div>Chemistry</div>
        <div>Mathematics</div>
        <div>Computer Science</div>
        </div>`,
    },
    {
        topic:'4',
        content:`<div>
        <div>Physics</div>
        <div>Chemistry</div>
        <div>Biology or Mathematics</div>
        <div>Tamil</div>
        </div>`,
    },
    {
        topic:'5',
        content:`<div>
        <div>Accountancy</div>
        <div>Computer Science or Mathematics</div>
        <div>Economics</div>
        <div>Business Studies</div>
        </div>`,
    },
]
export default curriculumarr