import { MdInfoOutline, MdOutlineDone } from "react-icons/md";
import Slider from "../components/Slider";
import i1 from '../assets/images/header3.jpg'
import logo from '../assets/images/logo.png'
import { useState } from "react";
function Admission() {
  const admissionQuick = [
    {
      title: "Admission",
      link: "admission"
    }
  ]
  const [attachmentData,setAttachementData]=useState({
    birth:'',
    momPic:'',
    dadPic:'',
    childAadhar:'',
    childPic:'',
    parentAadhar:'',
  })
  const [studentData, setStudentData] = useState({
   class:'',
   name:'',
   dob:'',
   age:'',
   gender:'',
   aadhar:'',
   emis:'',
   tc:'',
   identification:'',
   contactperson:'',
   mobileno:'',
   blood:'',
   address:'',
   caste:'',
   distance:'', 
   sibling:'',
   noofpersoninfamily:'',
  })
  const [parentData, setParentData] = useState({
    father: {
      name: '',
      occupation: '',
      organisation: '',
      designation: '',
      address: '',
      annualincome: ''
    },
    mother: {
      name: '',
      occupation: '',
      organisation: '',
      designation: '',
      address: '',
      annualincome: ''
    }
  });
  const [healthData, setHealthData] = useState({
    problems: '',
    emergencyname: '',
    contact: '',
    relation: ''
  });
  const [otherData, setOtherData] = useState({
    prevschool: '',
    language: '',
    reason: '',
    adopted: false,
    parent: false
  });
  const handleOtherChange = (e) => {
    const { name, value, type } = e.target;
    setOtherData({
      ...otherData,
      [name]: type === 'checkbox' ? e.target.checked : value,
    });
  };
  const handleHealthChange = (e) => {
    const { name, value } = e.target;
    setHealthData({
      ...healthData,
      [name]:value,
    })
  };
  const convertToBase64 = (e) => {
    const file = e.target.files[0];
    const {name,value}=e.target
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setAttachementData({
          ...attachmentData,[name]:reader.result
        });
      };
      reader.onerror = error => {
        console.log(error);
      };
    }
  };
  // Handle change function to update the parent's data
  const handleParentChange = (e) => {
    const { name, value } = e.target;
    const [parent, field] = name.split('.');
    setParentData(prevState => ({
      ...prevState,
      [parent]: {
        ...prevState[parent],
        [field]: value
      }
    }));
  };

  const handleChange = (event) => {
    const { name, value,type } = event.target;
    setStudentData({
      ...studentData,
      [name]: type === 'checkbox' ? event.target.checked : value,
    });
  };

  const handleSubmit=async(e)=>{
    const res=await fetch('https://api.bgsthimiri.com/admission/',{
      method:'POST',
      headers:{'Content-type': 'application/json',
      Accept: 'application/json',
      "Access-Control-Allow-Origin": "*"},
      body:JSON.stringify({studentData,healthData,otherData,parentData,attachmentData})
    })
    if(res.ok){
      alert('submited')
    }else{
      alert('something wrong')
    }
  }

  return (
    <div>
      <Slider images={[i1]} menuBackgroundImage={logo} quicklinks={admissionQuick} interval={5000} title={"ADMISSION"} />
      <div id="admission" className='outer-border'>
        <div className='border'>
          <h1 className='admin'>ADMISSINON FORM</h1>
          <div className='student-outer-border'>
            <h1>Student details<sup className='start'>*</sup></h1>
            <div className='student-border'>
              <div className='student-left'>
                <label htmlFor="" className='lable' >Class to which Admission is sought<sup className='start'>*</sup></label><br /><br />
                <select
                  name="class"
                  id="classAdmission"
                  className='select'
                  value={studentData.classAdmission}
                  onChange={handleChange}
                >
                  <option value="">Choose</option>
                  <option value="PRE-KG">PRE-KG</option>
                  <option value="UKG">UKG</option>
                  <option value="LKG">LKG</option>
                  <option value="1st Std">1st Std</option>
                  <option value="2nd Std">2nd Std</option>
                  <option value="3rd Std">3rd Std</option>
                  <option value="4th Std">4th Std</option>
                  <option value="5th Std">5th Std</option>
                  <option value="6th Std">6th Std</option>
                  <option value="7st Std">7st Std</option>
                  <option value="8st Std">8st Std</option>
                  <option value="9th Std">9th Std</option>
                  <option value="10th Std">10th Std</option>
                  <option value="+1">+1</option>
                  <option value="+2">+2</option>
                </select>

                <br /><br />
                <label htmlFor="" className='lable' >Date Of Birth <sup className='start'>*</sup></label><br /><br />
                <input
                  type="date"
                  name="dob"
                  className='input'
                  value={studentData.dateOfBirth}
                  onChange={handleChange}
                />
                <br /><br />

                <div className='gender'>
                  <label htmlFor="gender" className='label'>Gender<sup className='start'>*</sup></label><br /><br />
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    value="male"
                    className='radio male'
                    checked={studentData.gender === 'male'}
                    onChange={handleChange}
                  />
                  <label htmlFor="male">Male</label>

                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="female"
                    className='radio female'
                    checked={studentData.gender === 'female'}
                    onChange={handleChange}
                  />
                  <label htmlFor="female">Female</label>
                  <br /><br />

                </div>
                <label className='lable'>Emis No (16 Digits)<sup className='start'>*</sup></label><br /><br />
                <input
                  type="text"
                  name="emis"
                  id="emisNo"
                  className='input'
                  value={studentData.emisNo}
                  onChange={handleChange}
                />
                <br /><br />

                <label className='lable'>Identification Mark<sup className='start'>*</sup></label><br /><br />
                <input
                  type="text"
                  name="identification"
                  id="identificationMark"
                  className='input'
                  value={studentData.identificationMark}
                  onChange={handleChange}
                />

                <br /><br />
                <label className='lable'>Mobile No<sup className='start'>*</sup></label><br /><br />
                <input
                  type="text"
                  name="mobileno"
                  id="mobileNo"
                  className='input'
                  value={studentData.mobileNo}
                  onChange={handleChange}
                /><br /><br />

              </div>
              <div className='student-right'>
                <label className='lable'>Student`s Name<sup className='start'>*</sup></label><br /><br />
                <input
                  type="text"
                  name="name"
                  id="studentName"
                  className='input'
                  value={studentData.studentName}
                  onChange={handleChange}
                />

                <br /><br />
                <label className='lable'>Age as on 31<sup>st</sup> March<sup className='start'>*</sup></label><br /><br />
                <input
                  type="text"
                  name="age"
                  id="ageOnMarch31"
                  className='input'
                  value={studentData.ageOnMarch31}
                  onChange={handleChange}
                />

                <br /><br />
                <label className='lable'>Adhar No (12 Digits)<sup className='start'>*</sup></label><br /><br />
                <input
                  type="text"
                  name="aadhar"
                  id="aadharNumber"
                  className='input'
                  value={studentData.aadharNumber}
                  onChange={handleChange}
                /><br /><br />

                <label className='lable'>TC No<sup className='start'>*</sup></label><br /><br />
                <input
                  type="text"
                  name="tc"
                  id="tcNumber"
                  className='input'
                  value={studentData.tcNumber}
                  onChange={handleChange}
                />

                <br /><br />
                <label className='lable'>Contact Person(Name)<sup className='start'>*</sup></label><br /><br />
                <input
                  type="text"
                  name="contactperson"
                  id="contactPersonName"
                  className='input'
                  value={studentData.contactPersonName}
                  onChange={handleChange}
                />

                <br /><br />
                <label className='lable'>Blood Group<sup className='start'>*</sup></label><br /><br />
                <input
                  type="text"
                  name="blood"
                  id="bloodGroup"
                  className='input'
                  value={studentData.bloodGroup}
                  onChange={handleChange}
                />

              </div>
            </div>
          </div>
          <div className='address'>
            <label htmlFor="" className='lable'>Address<sup className='start'>*</sup></label><br />
            <input
              type="text"
              className='addressinput'
              placeholder='Address line1'
              value={studentData.addressLine1}
              onChange={(e) => setStudentData({ ...studentData, addressLine1: e.target.value })}
            /><br />
            <input
              type="text"
              className='addressinput'
              placeholder='Address line2'
              value={studentData.addressLine2}
              onChange={(e) => setStudentData({ ...studentData, addressLine2: e.target.value })}
            />

          </div>
          <div className='addresspin'>
            <div>
              <br /><input
                type="text"
                name="cityTown"
                id="cityTown"
                className='inputadd'
                placeholder='City/Town*'
                value={studentData.cityTown}
                onChange={handleChange}
              />

            </div>
            <div>
              <br />  <input
                type="text"
                name="pincode"
                id="pincode"
                className='inputadd'
                placeholder='Pincode*'
                value={studentData.pincode}
                onChange={handleChange}
              />

            </div>
          </div>
          <div className='caste'>
            <div>
              <br />
              <label className='lable'>Caste<sup className='start'>*</sup></label><br /><br />
              <input
                type="text"
                name="caste"
                id="caste"
                className='input'
                value={studentData.caste}
                onChange={handleChange}
              />

              <br /><br />
              <label className='lable'>Siblings(brothers/sisters)<sup className='start'>*</sup></label><br /><br />
              <input
                type="radio"
                id="siblingsYes"
                name="sibling"
                value="yes"
                className='inputsiblings'
                // checked={studentData.sibling === "yes"}
                onChange={handleChange}
              />
              <label htmlFor="siblingsYes" className='yes'>Yes</label>
              <input
                type="radio"
                id="siblingsNo"
                name="sibling"
                value="no"
                className='inputsiblings'
                // checked={studentData.sibling=== "no"}
                onChange={handleChange}
              />
              <label htmlFor="siblingsNo" className='no'>No</label><br /><br />

            </div>
            <div>
              <br />
              <label className='lable'>Distance to school<sup className='start'>*</sup></label><br /><br />
              <input
                type="text"
                name="distance"
                id="distanceToSchool"
                className='input'
                value={studentData.distanceToSchool}
                onChange={handleChange}
              />

              <br /><br />
              <label className='lable'>Number of members in Family<sup className='start'>*</sup></label><br /><br />
              <input
                type="text"
                name="noofpersoninfamily"
                id="familyMembers"
                className='input'
                value={studentData.familyMembers}
                onChange={handleChange}
              />

              <br /><br />
            </div>
          </div>
          <div className='parent-topic'>
            <h1>Parents Details<sup className='start'>*</sup></h1>
          </div>

          <div className='parents'>
            <div>
              <h4>Father<sup className='start'>*</sup></h4><br />
              <input
                type="text"
                name="father.name"
                className='input'
                placeholder='Father Name*'
                value={parentData.father.name}
                onChange={handleParentChange}
              />

              <input
                type="text"
                name="father.occupation"
                className='input'
                placeholder='Profession/Organisation*'
                value={parentData.father.occupation}
                onChange={handleParentChange}
              />

              <input
                type="text"
                name="father.organisation"
                className='input'
                placeholder='Name Of Organisation*'
                value={parentData.father.organisation}
                onChange={handleParentChange}
              />

              <input
                type="text"
                name="father.designation"
                className='input'
                placeholder='Designation*'
                value={parentData.father.designation}
                onChange={handleParentChange}
              />

              <input
                type="text"
                name="father.address"
                className='input'
                placeholder='Office Address*'
                value={parentData.father.address}
                onChange={handleParentChange}
              />

              <input
                type="text"
                name="father.annualincome"
                className='input'
                placeholder='Annual Income Father*'
                value={parentData.father.annualincome}
                onChange={handleParentChange}
              />

            </div>

            <div>

              <h4>Mother<sup className='start'>*</sup></h4><br />
              <input
                type="text"
                name="mother.name"
                className='input'
                placeholder='Mother Name*'
                value={parentData.mother.name}
                onChange={handleParentChange}
              />

              <input
                type="text"
                name="mother.occupation"
                className='input'
                placeholder='Profession/Organisation*'
                value={parentData.mother.occupation}
                onChange={handleParentChange}
              />

              <input
                type="text"
                name="mother.organisation"
                className='input'
                placeholder='Name Of Organisation*'
                value={parentData.mother.organisation}
                onChange={handleParentChange}
              />

              <input
                type="text"
                name="mother.designation"
                className='input'
                placeholder='Designation*'
                value={parentData.mother.designation}
                onChange={handleParentChange}
              />

              <input
                type="text"
                name="mother.address"
                className='input'
                placeholder='Office Address*'
                value={parentData.mother.address}
                onChange={handleParentChange}
              />
              <input
                type="text"
                name="mother.annualincome"
                className='input'
                placeholder='Annual Income Mother*'
                value={parentData.mother.annualincome}
                onChange={handleParentChange}
              />
            </div>
          </div>
          <div><h1 className='studentdet'>Health Details<sup className='start'>*</sup></h1></div>
          <div className='healthdetails'>
            <div>
              <input
                type="text"
                name="problems"
                className='input'
                placeholder='Allergy / Chronic Ailment / Handicap*'
                value={healthData.allergy}
                onChange={handleHealthChange}
              />

              <br /><br />

              <input
                type="text"
                name="contact"
                className='input'
                placeholder='Contact Number*'
                value={healthData.contact}
                onChange={handleHealthChange}
              />
            </div>
            <div className='healthright'>

              <input
                type="text"
                name="emergencyname"
                className='input'
                placeholder='Emergency Contact Person (Name)*'
                value={healthData.emergencyname}
                onChange={handleHealthChange}
              />

              <br /><br />

              <input
                type="text"
                name="relation"
                className='input'
                placeholder='Relation*'
                value={healthData.relation}
                onChange={handleHealthChange}
              />
            </div>
          </div>

          <div><h1>Other Details<sup className='start'>*</sup></h1></div>
          <div className='otherdetails'>
            <div>
              <form action="">
                <label className='lable'>Is Your Child Adopted?<sup className='start'>*</sup></label><br /><br />
                <input
                  type="radio"
                  id="adoptedYes"
                  name="adopted"
                  value="Yes"
                  className='inputsiblings'
                  onChange={handleOtherChange}
                />
                <label className='yes' htmlFor='adoptedYes'>Yes</label>

                <input
                  type="radio"
                  id="adoptedNo"
                  name="adopted"
                  value="No"
                  className='inputsiblings'
                  onChange={handleOtherChange}
                />
                <label className='no' htmlFor='adoptedNo'>No</label>
                

              </form>
            </div>
            <div>
              <label className='lable'>Single Parent?<sup className='start'>*</sup></label><br /><br />
              <input
                type="radio"
                id="singleParentYes"
                name="parent"
                value="yes"
                className='inputsiblings'
                checked={otherData.parent === 'yes'}
                onChange={handleOtherChange}
              />
              <label className='yes' htmlFor='singleParentYes'>Yes</label>

              <input
                type="radio"
                id="singleParentNo"
                name="parent"
                value="no"
                className='inputsiblings'
                checked={otherData.parent === 'no'}
                onChange={handleOtherChange}
              />
              <label className='no' htmlFor='singleParentNo'>No</label>

              <input
                type="text"
                name="prevschool"
                id="prevSchool"
                className='input'
                placeholder='Name Of Previous School Studied*'
                value={otherData.prevschool}
                onChange={handleOtherChange}
              />
              <input
              type="text"
              name="language"
              id="language"
              className='otherinput'
              placeholder='Languages Known*'
              value={otherData.language}
              onChange={handleOtherChange}
            />

            </div>
          </div>
          <div className='other-flex'>
            <input
              type="text"
              name="reason"
              id="reason"
              className='otherinput'
              placeholder='Reason for applying the school*'
              value={otherData.reason}
              onChange={handleOtherChange}
            />

          </div>
          <div>
            <br />
            <h1 className='studentdet'>Attachments<sup className='start'>*</sup></h1>
            <h3>(File Size Less Than 500KB)</h3><br />
          </div>
          <div className='attachment'>
            <div>
              <label htmlFor="" className='lable'>Child's-Birth Certificate <sup className='start'>*</sup></label><br /><br />
              <input type="file" onChange={convertToBase64}  name="birth" id="" accept='.jpg, .jpeg, .png' />
              <br /><br />
              <label htmlFor="" className='lable'>Father/Guardian's Photographs <sup className='start'>*</sup></label><br /><br />
              <input type="file" onChange={convertToBase64} name="momPic" id="" accept='.jpg, .jpeg, .png' />
              <br /><br />
              <label htmlFor="" className='lable'>Mother/Guardian's Photographs <sup className='start'>*</sup></label><br /><br />
              <input type="file" onChange={convertToBase64} name="dadPic" id="" accept='.jpg, .jpeg, .png' />
            </div>
            <div>
              <label htmlFor="" className='lable'>Child's-Aadhar card  <sup className='start'>*</sup></label><br /><br />
              <input type="file" onChange={convertToBase64} name="childAadhar" id="" accept='.jpg, .jpeg, .png' />
              <br /><br />
              <label htmlFor="" className='lable'>Child's-Photograph  <sup className='start'>*</sup></label><br /><br />
              <input type="file" onChange={convertToBase64} name="childPic" id="" accept='.jpg, .jpeg, .png' />
              <br /><br />
              <label htmlFor="" className='lable'>Parent's/Guardian's-Aadhar Card<sup className='start'>*</sup></label><br /><br />
              <input type="file" onChange={convertToBase64} name="parentAadhar" id="" accept='.jpg, .jpeg, .png' />
            </div>
          </div>
          <div className='checkbox'>
            <input type="checkbox" className='check' />
            <label htmlFor="" className='decleration'><h4>I declare that the information furnished in this application is complete and accurate</h4></label>
          </div>
          <div className='btn'>
            <button className='cancel'>Cancel</button>
            <button onClick={(e)=>{
              console.log(studentData)
              console.log(otherData)
              console.log(parentData)
              console.log(healthData)
              handleSubmit()
            }} className='submit'>Submint</button>
          </div>


        </div>
        <div>
          <h1 className='instruction'>General Instructios</h1>
          <div className='general'>
            <p>Bharathidhasanar Matric Hr Sec School, Arakkonam is a complete day school offering stateboard syllabus for students aged 5-16 years respectively. Admission is open to grades LKG to 9 and 11 students. Admission is based on First-cum-First serve basis and the level of performance and personal interview. However, admission shall primarily depend on binding. The school reserves the right to deny/withdraw an application/admission to any of the student at any stage of the admission process, and the decision of the school is final.</p>
            <ul>
              <dt className="flex"><div><MdOutlineDone className="" /></div><div> The online registration forms will be available at the school website from 23.03.2024 (saturday) to 23.04.2024 (tuesday). The last date for receiving all forms is 30.04.2020(tuesday).</div></dt>
              <dt className="flex"><div><MdOutlineDone className="" /></div><div> Completed Registration Form needs to be submitted online latest by 30.04.2020 (tuesday).</div></dt>
              <dt className="flex"><div><MdOutlineDone className="" /></div><div> Children whose birth date fails between 1st April 2020 and 31st March 2021 are eligible for registration for the academic session 2024-25. The child must be 3 years by 31.03.2024</div></dt>
              <dt className="flex"><div><MdOutlineDone className="" /></div><div> Those who will be selected will be called for the verification of the submitted data. Please bring hard copies of the pre-filled and signed online Registration Form along with the self-attested photocopies of the original documents as supporting evidence; on the day of verification. Submission of incorrect data may lead to cancellation of place on the list.</div></dt>
              <dt className="flex"><div><MdOutlineDone className="" /></div><div> Documents to bring at the time of admission:</div></dt>
              <dl className="flex"><div><MdOutlineDone className="" /></div><div> Child's-Aadhar Card/4 Photographs and TC original (K.G.Onwards), community certificate, Ration card, EMIS number (except LKG)</div></dl>
              <dl className="flex"><div><MdOutlineDone className="" /></div><div> Parent's/Guardian's-Aadhar Card/Address Proof and 4 Photographs each.</div></dl>
              <dt className="flex"><div><MdOutlineDone className="" /></div><div> Self attested documents to be uploaded along with the Registration Form: </div></dt>
              <dl className="flex"><div><MdOutlineDone className="" /></div><div> Child's-Birth Certificate, Aadhar card, TC original and 2 Photographs.</div></dl>
              <dl className="flex"><div><MdOutlineDone className="" /></div><div> Parent's/Guardian's-Aadhar Card and 2 Photographs each. Self attested documents required at the time of admission.</div></dl>
            </ul>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Admission;
