
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";


const AdminHome = () => {
  return (
    <div className="home">
      <Sidebar/>
      <div className="homeContainer">
        <Navbar/>
        <div className="widgets">
          <Widget type="faculty" />
          <Widget type="achievement" />
          <Widget type="gallery" />
          <Widget type="event" />
        </div>
        
      </div>
    </div>
  );
};

export default AdminHome;
