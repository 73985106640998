import React from 'react';
import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
const FacilityBox = ({ title, image, story, index }) => {
  // Determine background and text colors based on index
  const bgColor = index % 2 === 0 ? 'bg-blue-900' : 'bg-[#f42f54]';
  const textColor = index % 2 === 0 ? 'text-white' : 'text-white';
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className={`md:flex border-[1px] w-[1100px] md:w-fit my-20 md:my-0 shadow-[5px_5px_rgb(107,114,128)] rounded-3xl border-blue-950 p-10 ${bgColor} ${textColor}`}   data-aos={index % 2 === 0 ? 'fade-left' : 'fade-right'}
    data-aos-offset="200">
      <div className="">
        <img className="md:w-[800px] md:rounded-[100%] w-full h-full" src={image} alt="Facility Image" />
      </div>
      <div className="md:ml-10 my-auto text-justify">
        <div className="text-center md:text-4xl my-16 md:my-0 lg:text-3xl text-9xl mb-5">
          {title}
        </div>
        <div className="text-5xl leading-normal md:text-2xl lg:text-sm mt-3">
          {story}
        </div>
      </div>
    </div>
  );
};

export default FacilityBox;
