import i1 from '../assets/images/computerlab.jpeg'
import i2 from '../assets/images/rdlab.jpeg'
import i3 from '../assets/images/ground.jpeg'
import i4 from '../assets/images/roboticlab.jpeg'
import i5 from '../assets/images/childbilliards.jpeg'
import i6 from '../assets/images/playground.jpeg'
import i7 from '../assets/images/tableten.jpeg'
import i8 from '../assets/images/sciencelab.jpeg'
import i9 from '../assets/images/auditorium.jpeg'
import i10 from '../assets/images/transport.jpeg'
import i11 from '../assets/images/Lib.jpeg'
export const facilityarr=[
    {
        image:i1,
        topic:"Computer Lab",
        story:"Equipped with state-of-the-art technology and guided by expert instructors, our computer lab offers students hands-on learning experiences to master essential digital skills and excel in the digital age.",
    },
    {
        image:i2,
        topic:"Research and Development Lab",
        story:"Our R&D lab is a hub of innovation where students explore cutting-edge technologies and collaborate on research projects, fostering creativity and critical thinking skills essential for solving real-world challenges.",
    },
    {
        image:i3,
        topic:"Athletics Ground",
        story:"Our athletics ground provides students with a spacious and well-maintained environment to develop physical fitness, sportsmanship, and teamwork. Equipped with modern facilities, it serves as a vibrant hub for training, competitions, and fostering a culture of health and wellness among our students.",
    },
    {
        image:i4,
        topic:"Robotic Lab",
        story:"Our robotic lab offers students an immersive learning environment to explore robotics and automation technologies, fostering creativity and problem-solving skills crucial for future innovation.",
    },
    {
        image:i5,
        topic:"Billiards Room",
        story:"Equipped with top-tier billiards tables, our billiards room provides students with a recreational space to unwind and develop strategic thinking and precision.",
    },
    {
        image:i6,
        topic:"Kids Playground",
        story:"Our expansive kids playground offers a safe and stimulating environment where children can play, socialize, and engage in physical activities, promoting healthy development and fun-filled learning experiences.",
    },
    {
        image:i7,
        topic:"Table Tennis Room",
        story:"In our table tennis room, students enjoy competitive games and refine their hand-eye coordination, agility, and sportsmanship in a dynamic and supportive setting.",
    },
    {
        image:i8,
        topic:"Science Lab",
        story:"Our science lab is equipped with advanced equipment and resources, providing students with hands-on experiences to explore scientific concepts and conduct experiments, fostering curiosity and critical thinking skills.",
    },
    {
        image:i9,
        topic:"Auditorium",
        story:"The auditorium serves as a versatile space for school events, performances, and presentations, equipped with modern audiovisual technology to enhance learning and cultural experiences.",
    },
    {
        image:i10,
        topic:"Bus Transport",
        story:"Our transport services ensure safe and efficient transportation for students, supporting their access to education and extracurricular activities while prioritizing comfort and reliability.",
    },
    {
        image:i11,
        topic:"Library",
        story:"Our library offers a vast collection of books, digital resources, and study spaces, fostering a love for reading and supporting academic excellence through research and lifelong learning.",
    },
]