import React, { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar/Sidebar';
import Navbar from '../components/navbar/Navbar';

const AdminGallery = () => {
  const [imagearr, setImagearr] = useState([]);
  const [videoarr, setVideoarr] = useState([]);
  const [image, setImage] = useState();
  const [type, setType] = useState();
  const [link, setLink] = useState();
  const [activeTab, setActiveTab] = useState('existingImages');
  const [loading,setLoading]=useState(true)

  const levels = [
    { value: '01', label: 'Primary' },
    { value: '02', label: 'Secondary' },
    { value: '03', label: 'Higher-Secondary' },
  ];

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const getImage = async () => {
    try {
      setLoading(true)
      const result = await fetch('https://api.bgsthimiri.com/image/', {
        method: 'GET',
      });
      if (!result.ok) {
        throw new Error('Failed to fetch Images');
      }
      const response = await result.json();
      setImagearr(response);
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false)
    }
  };

  const getVideo = async () => {
    try {
      const result = await fetch('https://api.bgsthimiri.com/video/', {
        method: 'GET',
      });
      if (!result.ok) {
        throw new Error('Failed to fetch Videos');
      }
      const response = await result.json();
      setVideoarr(response);
    } catch (error) {
      console.log(error);
    }
  };

  const addImage = async () => {
    const res = await fetch('https://api.bgsthimiri.com/image/', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ type, file: image }),
    });
    if (res.ok) {
      alert('Image uploaded');
    } else {
      alert('Something wrong');
    }
  };

  const addVideo = async () => {
    const res = await fetch('https://api.bgsthimiri.com/video/', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ type, link }),
    });
    if (res.ok) {
      alert('Video uploaded');
    } else {
      alert('Something wrong');
    }
  };

  const deleteImage = async (id) => {
    const res = await fetch('https://api.bgsthimiri.com/image/' + id, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
    if (res.ok) {
      alert('Image deleted');
      getImage();
    } else {
      alert('Something wrong');
    }
  };

  const deleteVideo = async (id) => {
    const res = await fetch('https://api.bgsthimiri.com/video/' + id, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
    if (res.ok) {
      alert('Video deleted');
      getVideo();
    } else {
      alert('Something wrong');
    }
  };

  const convertToBase64 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.onerror = (error) => {
        console.log(error);
      };
    }
  };

  useEffect(() => {
    getImage();
    getVideo();
  }, []);

  return (
    <div className='w-full flex'>
      <Sidebar />
      <div className='flex-[6]'>
        <Navbar />
        {loading ? (
        <div className="loading-overlay">
          <div className="loader"></div>
        </div>

    ) : (<>
        <div>
          <div className='flex mb-4'>
            <button onClick={() => toggleTab('existingImages')} className={`mr-4 ${activeTab === 'existingImages' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}>Existing Images</button>
            <button onClick={() => toggleTab('existingVideos')} className={`mr-4 ${activeTab === 'existingVideos' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}>Existing Videos</button>
            <button onClick={() => toggleTab('addImage')} className={`mr-4 ${activeTab === 'addImage' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}>Add Image</button>
            <button onClick={() => toggleTab('addVideo')} className={`mr-4 ${activeTab === 'addVideo' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}>Add Video</button>
          </div>
          {activeTab === 'existingImages' && (
              
            <div>
              {imagearr.map((e1, index) => (
                <div key={index} className='mb-4 border-b pb-4'>
                  <div>
                    <label className='font-bold'>Type:</label>
                    <input type='text' value={e1.type} readOnly className='border-[1px] border-gray-300 rounded px-2 py-1 w-64' />
                  </div>
                  <div>
                    <label className='font-bold'>Image:</label>
                    <img src={e1.file} alt={`Image ${index}`} className='w-96 h-96' />
                  </div>
                  <div>
                    <button onClick={() => deleteImage(e1._id)} className='bg-red-500 text-white px-4 py-1 rounded'>Delete</button>
                  </div>
                </div>
              ))}
            </div>
          )}
          {activeTab === 'existingVideos' && (
            <div>
              {videoarr.map((e1, index) => (
                <div key={index} className='mb-4 border-b pb-4'>
                  <div>
                    <label className='font-bold'>Type:</label>
                    <input type='text' value={e1.type} readOnly className='border-[1px] border-gray-300 rounded px-2 py-1 w-64' />
                  </div>
                  <div>
                    <label className='font-bold'>Link:</label>
                    <input type='text' value={e1.link} readOnly className='border-[1px] border-gray-300 rounded px-2 py-1 w-64' />
                  </div>
                  <div>
                    <button onClick={() => deleteVideo(e1._id)} className='bg-red-500 text-white px-4 py-1 rounded'>Delete</button>
                  </div>
                </div>
              ))}
            </div>
          )}
          {activeTab === 'addImage' && (
            <div>
              <div className='mb-4 font-bold'>ADD IMAGE</div>
              <div>
                <label className='font-bold'>Level:</label>
                <select value={type} onChange={(e) => setType(e.target.value)} className='border-[1px] border-gray-300 rounded px-2 py-1 mb-2 w-64'>
                  <option value=''>Select Level</option>
                  {levels.map((month) => (
                    <option key={month.value} value={month.label}>
                      {month.label}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className='font-bold'>Upload Image:</label>
                <input type='file' accept='/images/*' onChange={convertToBase64} className='border-[1px] border-gray-300 rounded px-2 py-1 mb-2 w-64' />
              </div>
              <div>
                <input type='submit' value='Add Image' onClick={addImage} className='px-4 py-2 bg-green-500 text-white rounded cursor-pointer hover:bg-green-600' />
              </div>
            </div>
          )}
          {activeTab === 'addVideo' && (
            <div>
              <div className='mb-4 font-bold'>ADD VIDEO</div>
              <div>
                <label className='font-bold'>Level:</label>
                <select value={type} onChange={(e) => setType(e.target.value)} className='border-[1px] border-gray-300 rounded px-2 py-1 mb-2 w-64'>
                  <option value=''>Select Level</option>
                  {levels.map((month) => (
                    <option key={month.value} value={month.label}>
                      {month.label}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className='font-bold'>Upload Video URL:</label>
                <input onChange={(e) => setLink(e.target.value)} type='text' className='border-[1px] border-gray-300 rounded px-2 py-1 mb-2 w-64' />
              </div>
              <div>
                <input type='submit' value='Add Video' onClick={addVideo} className='px-4 py-2 bg-green-500 text-white rounded cursor-pointer hover:bg-green-600' />
              </div>
            </div>
          )}
        </div>
        </>
    )}
      </div>
    </div>
  );
};

export default AdminGallery;
