import pdf from '../assets/images/cbsedoc.pdf'
export const documents=[
    {
        name:"ACADEMIC CALENDER",
        pdf:pdf,
    },
    {
        name:"REVISED LIST OF HOLIDAYS",
        pdf:pdf,
    },
    {
        name:"PTA MEMBER LIST",
        pdf:pdf,
    },
    {
        name:"NOC OF STATE GOVERNMENT",
        pdf:pdf,
    },
    {
        name:"SAFE DRINKING WATER AND HYGENIC ",
        pdf:pdf,
    },
    {
        name:"LAND CERTIFICATE",
        pdf:pdf,
    },
    {
        name:"BUILDING SAFETY CERTIFICATE",
        pdf:pdf,
    },
    {
        name:"FIRE SAFETY CERTIFICATE",
        pdf:pdf,
    },
    {
        name:"SEXUAL HARRASMENT COMMITTEE",
        pdf:pdf,
    },
    {
        name:"POCSO COMMITTEE",
        pdf:pdf,
    },
    {
        name:"GREIVANCE REDRESSAL COMMITTEE",
        pdf:pdf,
    },
    {
        name:"DEO CERTIFICATE",
        pdf:pdf,
    },
    {
        name:"SCHOOL STRENGTH",
        pdf:pdf,
    },
    {
        name:"SCHOOL ANNUAL REPORT",
        pdf:pdf,
    },
]