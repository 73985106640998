import i1 from '../assets/images/skating.jpeg'
import i2 from '../assets/images/archery.jpeg'
import i3 from '../assets/images/yoga.jpeg'
import i4 from '../assets/images/fencing.jpeg'
import i5 from '../assets/images/silambam.jpeg'
import i6 from '../assets/images/dance.jpeg'
import i7 from '../assets/images/keyboard.jpeg'
import i8 from '../assets/images/drums.jpeg'
import i9 from '../assets/images/scout.jpeg'
import i10 from '../assets/images/ncc.jpeg'
import i11 from '../assets/images/tabela.jpeg'
import i12 from '../assets/images/takewondo.jpeg'
import i13 from '../assets/images/chess.jpeg'
import i14 from '../assets/images/karatae.jpeg'
import i15 from '../assets/images/hocky.jpeg'
import i16 from '../assets/images/billiards.jpeg'
import i17 from '../assets/images/football.jpeg'
const sports=[
    {
      topic:"Skating",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i1,
    },
    {
      topic:"Archery",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i2,
    },
    {
      topic:"Yoga",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i3,
    },
    {
      topic:"Fencing",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i4,
    },
    {
      topic:"Silambam",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i5,
    },
    {
      topic:"Bharathanatiyam",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i6,
    },
    {
      topic:"Keyboard",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i7,
    },
    {
      topic:"Drums",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i8,
    },
    {
      topic:"Scout",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i9,
    },
    {
      topic:"NCC",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i10,
    },
    {
      topic:"Tabla",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i11,
    },
    {
      topic:"Taekwondo",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i12,
    },
    {
      topic:"Chess",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i13,
    },
    {
      topic:"Karate",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i14,
    },
    {
      topic:"Hockey",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i15,
    },
    {
      topic:"Billiards",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i16,
    },
    {
      topic:"Football",
      story:"Discover the vibrant sports culture at BharathiDhasanar Public School, where we believe in fostering physical wellness and teamwork alongside academic excellence. Our comprehensive sports program is designed to cultivate the athletic talents and character of our students. A Diverse Range of Athletic Opportunities Explore a wide array of athletic activities tailored to suit every interest and ability level. From popular team sports like basketball, football, and volleyball to individual pursuits such as track and field, swimming, and tennis, our program offers something for everyone.",
      image:i17,
    },
    ]
export default sports