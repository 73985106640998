
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Layout from './components/Layout';
import Academics from './pages/Academics';
import Facilities from './pages/Facilities';
import Achievements from './pages/Achievements';
import Career from './pages/Career';
import Admission from './pages/Admission';
import Contact from './pages/Contact';
import CbseDocuments from './pages/CbseDocuments'
// import Gallery from './pages/Gallery';
import AdminEvents from './pages/AdminEvents';
import AdminFaculty from './pages/AdminFaculty';
import AdminAchievements from './pages/AdminAchievements';
import AdminGallery from './pages/AdminGallery';
import { DarkModeContext } from "./components/context/darkModeContext";
import React, { useContext } from 'react';
import AdminLayout from './components/AdminLayout';
import AdminHome from './components/home/AdminHome';
import AdminCareer from './pages/AdminCareer';
import AdminAdmission from './pages/AdminAdmission';
import ImageContainer from './components/ImageContainer';
import AdminContact from './pages/AdminContact';
import AdminLogin from './pages/AdminLogin';
import Admin from './pages/Admin';
import AdmissionForm from './pages/AdmissionForm';
const Gallery=React.lazy(()=>
  import('./pages/Gallery')
)
function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          
            <Route path='' element={<Home />} />
            <Route path='about' element={<About />} />
            <Route path='academics' element={<Academics />} />
            <Route path='facilities' element={<Facilities />} />
            <Route path='achievements' element={<Achievements />} />
            <Route path='career' element={<Career />} />
            <Route path='admission' element={<AdmissionForm/>} />
            <Route path='contact' element={<Contact />} />
            <Route path='public-disclosure' element={<CbseDocuments/>}/>
            
            {/* <Route path='sitemap.xml' element={<sitema}/> */}
            <Route path='gallery' element={
            <React.Suspense fallback={<div>loading</div>}>

            <Gallery/>
            </React.Suspense>
            } />
          
          <Route path='/admin' element={<Admin/>}>
          <Route path='dashboard' element={<AdminHome />}/>
            {/* <Route path='' element={<AdminHome/>}/> */}
            <Route path='event' element={<AdminEvents />} />
            <Route path='faculty' element={<AdminFaculty />} />
            <Route path='achievement' element={<AdminAchievements />} />
            <Route path='gallery' element={<AdminGallery />} />
            <Route path='career' element={<AdminCareer/>}/>
            <Route path='admission' element={<AdminAdmission/>}/>
            <Route path='contact' element={<AdminContact/>}/>
            <Route path='login' element={<AdminLogin/>}/>
          </Route>
       {/* </Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
