import React from 'react'
import Slider from '../components/Slider'
import i3 from '../assets/images/bh3.jpeg'
import logo from '../assets/images/logo.png'
import Underline from '../components/Underline'
import { facilityarr } from '../components/facilityarr'
import FacilityBox from '../components/FacilityBox'
import Footer from '../components/Footer'
import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
const Facilities = () => {
  useEffect(() => {
    AOS.init();
  }, []);
    const facilityQuick=[
        {title:"Facility",
      link:"facilities#facility"}
    ]
  return (
    <div className='w-[110vh] md:w-full'>
        <Slider title={"FACILITIES"} images={[i3]} menuBackgroundImage={logo} interval={5000} quicklinks={facilityQuick}/>
        <div id="facility">
        <div className="text-9xl md:text-5xl text-blue-950 text-center pt-16  font-bold" data-aos='zoom-in' data-aos-duration='1200'>
        FACILITIES
        <Underline/>
      </div>
      <div className="grid lg:grid-cols-2 md:gap-10 ml-14 md:ml-0 lg:mx-32 my-16">
        {facilityarr.map((f1,index)=>(
          <FacilityBox title={f1.topic} story={f1.story} image={f1.image} index={index}/>
        ))}
        
        
      </div>
        </div>
        <Footer></Footer>
    </div>
  )
}

export default Facilities