import React from 'react'
import i1 from '../assets/images/cbsebg.jpeg'
import { documents } from '../components/documents'
import i2 from '../assets/images/docu.gif'
import Slider from '../components/Slider'
import logo from '../assets/images/logo.png'
import Footer from '../components/Footer'
import Underline from '../components/Underline'
import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
const CbseDocuments = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const academicQuick=[
    {
        title:"Public Disclosure",
        link:"pdc"
    },
   
]
  return (
    
    <div className="font-serif w-[1200px] md:w-full">
      <Slider images={[i1]} menuBackgroundImage={logo} title={"PUBLIC DISCLOSURE"} interval={5000} quicklinks={academicQuick} />
        <div id='pdc' className="">
        <div className="text-9xl md:text-5xl text-blue-950 text-center pt-16 pb-16 md:pb-5  font-bold" data-aos="zoom-in" data-aos-duration="1200">
        CBSE DOCUMENTS
        <Underline/>
      </div>

        <div className="my-16 flex flex-col  md:grid md:grid-cols-2 ">
            {documents.map((m1,index)=>(
                <div className="flex shadow-2xl mx-auto text-5xl md:text-lg w-[800px] md:w-[500px] hover:bg-blue-100 duration-200 cursor-pointer rounded-md border-blue-950  p-10 my-16 md:my-5"   data-aos={index % 2 === 0 ? 'fade-left' : 'fade-right'}
                data-aos-offset="200">
                    <a href={m1.pdf} className="flex " target='_blank'>

                    <div><img className="w-10 md:w-5 " src={i2}/></div>
                    <div className='mx-5'>{m1.name}</div>
                    </a>
                </div>
            ))}
        </div>
            </div>
            <Footer/>
    </div>
  )
}

export default CbseDocuments