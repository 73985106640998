import React, { useState } from 'react';
import i1 from '../assets/images/b12.jpeg'
import menu from '../assets/images/logo.png'
import Slider from '../components/Slider';
import Footer from '../components/Footer';
function AdmissionForm() {
    const [formData, setFormData] = useState({
        classes: '',
        fullName: '',
        gender: 'Male',
        dateOfBirth: '',
        placeOfBirth: '',
        bloodGroup: '',
        studentPhoto: null,
        mobileNumber: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            studentPhoto: e.target.files[0],
        });
    };
    const galleryQuick = [
        {
            title: "Admission",
            link: 'admission'
        }
    ]
    const convertToBase64 = (e) => {
        const file = e.target.files[0];
        const { name } = e.target;
        if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            setFormData({
              ...formData,
              [name]: reader.result,
            });
          };
          reader.onerror = (error) => {
            console.log(error);
          };
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission
        const res=await fetch('https://api.bgsthimiri.com/admission/',{
            method:'POST',
            headers:{'Content-type': 'application/json',
            Accept: 'application/json',
            "Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({formData})
          })
          if(res.ok){
            alert('submited')
          }else{
            alert('something wrong')
          }
        console.log('Form submitted', formData);
    };

    return (
        <div className="w-[1100px] md:w-full">

            <Slider images={[i1]} menuBackgroundImage={menu} interval={5000} quicklinks={galleryQuick} title={"ADMISSION"} />
            <form id='admission' onSubmit={handleSubmit} className="mx-16 md:mx-60 px-16  border-dotted-[1px]  border-dotted-yellow-500 border-[1px] border-yellow-500 border-dotted-dotted my-16 p-4">
                <div className="text-center py-8 ">
                    <h1 className="text-7xl md:text-4xl font-bold flex flex-col">
                        <span className="text-red-500">Ready to Join the</span>
                        <span className="text-blue-900"> Bharathidasanar Group of Schools Family?</span>
                    </h1>
                    <p className="mt-10 md:mt-4 text-5xl md:text-lg leading-normal text-justify text-gray-700">
                        Have questions or need more information? Our admissions team is here to assist you every step of the way.
                        Reach out to us via phone, email, or by filling out our online inquiry form.
                    </p>
                </div>
                <div className="mb-32 md:mb-4">
                    <label className="block text-gray-700 text-5xl md:text-sm font-bold mb-2" htmlFor="class"es>
                        Class in which admission is sought for (*)
                    </label>
                    <select
                        name="classes"
                        id="classes"
                        value={formData.classes}
                        onChange={handleChange}
                        className="shadow appearance-none border-[1px] mt-10 md:mt-0 border-yellow-500 border-dotted rounded w-full text-5xl md:text-sm py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                        <option value="">--Please choose an option--</option>
                        <option value="PRE-KG">PRE-KG</option>
                        <option value="UKG">UKG</option>
                        <option value="LKG">LKG</option>
                        <option value="1st Std">1st Std</option>
                        <option value="2nd Std">2nd Std</option>
                        <option value="3rd Std">3rd Std</option>
                        <option value="4th Std">4th Std</option>
                        <option value="5th Std">5th Std</option>
                        <option value="6th Std">6th Std</option>
                        <option value="7st Std">7st Std</option>
                        <option value="8st Std">8st Std</option>
                        <option value="9th Std">9th Std</option>
                        <option value="10th Std">10th Std</option>
                        <option value="+1">+1</option>
                        <option value="+2">+2</option>
                    </select>
                </div>
                <div className="mb-32 md:mb-4">
                    <label className="block text-gray-700 text-5xl md:text-sm font-bold mb-2" htmlFor="fullName">
                        Name of the Child in full (in Capital Letters)(*)
                    </label>
                    <input
                        type="text"
                        name="fullName"
                        id="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        className="shadow appearance-none border-[1px] mt-10 md:mt-0 border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-32 md:mb-4">
                    <label className="block text-gray-700 text-5xl md:text-sm font-bold mb-2">
                        Gender(*)
                    </label>
                    <div className='text-5xl md:text-sm'>
                        <label className="mr-4">
                            <input
                                type="radio"
                                name="gender"
                                value="Male"
                                checked={formData.gender === 'Male'}
                                onChange={handleChange}
                                className="mr-1 h-10 md:h-3 md:w-3 w-10"
                            />
                            Male
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="gender"
                                value="Female"
                                checked={formData.gender === 'Female'}
                                onChange={handleChange}
                                className="mr-1 h-10 md:h-3 md:w-3 w-10"
                            />
                            Female
                        </label>
                    </div>
                </div>
                <div className="mb-32 md:mb-4">
                    <label className="block text-gray-700 text-5xl md:text-sm font-bold mb-2" htmlFor="dateOfBirth">
                        Date of Birth(*)
                    </label>
                    <input
                        type="date"
                        name="dateOfBirth"
                        id="dateOfBirth"
                        value={formData.dateOfBirth}
                        onChange={handleChange}
                        className="shadow appearance-none border-[1px] mt-10 md:mt-0 border-yellow-500 border-dotted rounded w-full text-5xl md:text-sm py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-32 md:mb-4">
                    <label className="block text-gray-700 text-5xl md:text-sm font-bold mb-2" htmlFor="placeOfBirth">
                        Place of Birth
                    </label>
                    <input
                        type="text"
                        name="placeOfBirth"
                        id="placeOfBirth"
                        value={formData.placeOfBirth}
                        onChange={handleChange}
                        className="shadow appearance-none border-[1px] mt-10 md:mt-0 border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-32 md:mb-4">
                    <label className="block text-gray-700 text-5xl md:text-sm font-bold mb-2" htmlFor="mobileNumber">
                        Mobile Number 
                    </label>
                    <input
                        type="number"
                        name="mobileNumber"
                        id="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        className="shadow appearance-none border-[1px] mt-10 md:mt-0 border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-32 md:mb-4">
                    <label className="block text-gray-700 text-5xl md:text-sm font-bold mb-2" htmlFor="bloodGroup">
                        Blood Group of the Child
                    </label>
                    <input
                        type="text"
                        name="bloodGroup"
                        id="bloodGroup"
                        value={formData.bloodGroup}
                        onChange={handleChange}
                        className="shadow appearance-none border-[1px] mt-10 md:mt-0 border-yellow-500 border-dotted rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-32 md:mb-4">
                    <label className="block text-gray-700 text-5xl md:text-sm font-bold mb-2" htmlFor="studentPhoto">
                        Student Photo(*)
                    </label>
                    <input
                        type="file"
                        name="studentPhoto"
                        id="studentPhoto"
                        onChange={convertToBase64}
                        className="shadow appearance-none border-[1px] mt-10 md:mt-0 border-yellow-500 border-dotted text-5xl md:text-sm rounded w-full py-10 md:py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-32 md:mb-4">
                    <button
                        type="submit"
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-10 md:py-2 px-10 md:px-4 rounded focus:outline-none text-5xl md:text-sm focus:shadow-outline"
                    >
                        Next
                    </button>
                </div>
            </form>
            <Footer />
        </div>
    );
}

export default AdmissionForm;
