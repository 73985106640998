import React, { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar/Sidebar';
import Navbar from '../components/navbar/Navbar';

const AdminAchievements = () => {
  const [editIndex, setEditIndex] = useState(null);
  const [achievement, setAchievement] = useState([]);
  const [topic, setTopic] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [image, setImage] = useState(null);

  const achType=[
    {value:'01',label:'Sports'},
    {value:'02',label:'Academics'},
  ]

  const convertToBase64 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.onerror = (error) => {
        console.error('File reading error:', error);
      };
    }
  };

  const getAchievements = async () => {
    try {
      const res = await fetch('https://api.bgsthimiri.com/achievement/', {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
      });
      if (res.ok) {
        const achievementInfo = await res.json();
        setAchievement(achievementInfo);
      } else {
        alert('Failed to fetch achievement data');
      }
    } catch (error) {
      console.error('Error fetching achievements:', error);
    }
  };

  const addAchievement = async () => {
    try {
      const res = await fetch('https://api.bgsthimiri.com/achievement/', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify({ topic, desc:description, type, file: image }),
      });
      if (res.ok) {
        alert('Achievement added successfully');
        getAchievements(); // Refresh achievement list after adding
      } else {
        alert('Failed to add achievement');
      }
    } catch (error) {
      console.error('Error adding achievement:', error);
    }
  };

  const deleteAchievement = async (id) => {
    try {
      const res = await fetch('https://api.bgsthimiri.com/achievement/' + id, {
        method: 'DELETE',
        headers: { 'Content-type': 'application/json' },
      });
      if (res.ok) {
        alert('Achievement deleted successfully');
        getAchievements(); // Refresh achievement list after deletion
      } else {
        alert('Failed to delete achievement');
      }
    } catch (error) {
      console.error('Error deleting achievement:', error);
    }
  };

  const handleEdit = async (id) => {
    try {
      const res = await fetch('https://api.bgsthimiri.com/achievement/' + achievement[id]._id, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify({ topic, description, type, file: image }),
      });
      if (res.ok) {
        alert('Achievement updated successfully');
        getAchievements(); // Refresh achievement list after update
        closeEditPopup();
      } else {
        alert('Failed to update achievement');
      }
    } catch (error) {
      console.error('Error updating achievement:', error);
    }
  };

  const openEditPopup = (index) => {
    setEditIndex(index);
    const { topic, desc, type, file } = achievement[index];
    setTopic(topic);
    setDescription(desc);
    setType(type);
    setImage(file);
  };

  const closeEditPopup = () => {
    setEditIndex(null);
    setTopic('');
    setDescription('');
    setType('');
    setImage(null);
  };

  useEffect(() => {
    getAchievements();
  }, []);

  return (
    <div className='w-full flex'>
      <Sidebar />
      <div className='flex-[6]'>
        <Navbar />
        <div className="bg-white p-6 rounded shadow mb-6">
          <h2 className="text-xl font-bold mb-4">EXISTING ACHIEVEMENTS</h2>
          <div>
            {achievement.map((e1, index) => (
              <div key={index} className="border-b border-gray-300 pb-4 mb-4">
                <div>
                  <label className="font-bold">Topic: </label>
                  <p>{e1.topic}</p>
                </div>
                <div>
                  <label className="font-bold">Description: </label>
                  <p>{e1.desc}</p>
                </div>
                <div>
                  <label className="font-bold">Type: </label>
                  <p>{e1.type}</p>
                </div>
                <div className="mt-2">
                  <button className="bg-blue-500 text-white px-4 py-1 rounded mr-2" onClick={() => openEditPopup(index)}>Edit</button>
                  <button className="bg-red-500 text-white px-4 py-1 rounded" onClick={() => deleteAchievement(e1._id)}>Delete</button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-white p-6 rounded shadow mb-6">
          <h2 className="text-xl font-bold mb-4">ADD ACHIEVEMENT</h2>
          <div>
            <div className="mb-4">
              <label className="font-bold">Topic:</label>
              <input onChange={(e) => {
                setTopic(e.target.value)
                }} type='text' className="border border-gray-300 rounded px-2 py-1 ml-2" />
            </div>
            <div className="mb-4">
              <label className="font-bold">Description:</label>
              <input onChange={(e) => setDescription(e.target.value)} type='text' className="border border-gray-300 rounded px-2 py-1 ml-2" />
            </div>
            <div className='mb-2'>
                  <label>Type: </label>
                  <select value={type} onChange={(e) => setType(e.target.value)} className={`border  rounded px-2 py-1`}>
                    <option value="">Select Type</option>
                    {achType.map(month => (
                      <option key={month.value} value={month.label}>{month.label}</option>
                    ))}
                  </select>
                  </div>
            <div className="mb-4">
              <label className="font-bold">Image:</label>
              <input type='file' onChange={(e) => convertToBase64(e)} accept='/photos/*' className="ml-2" />
            </div>
            <div>
              <button onClick={addAchievement} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">Add Achievement</button>
            </div>
          </div>
        </div>
        {editIndex !== null && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="bg-white rounded-lg p-8 max-w-md w-full">
              <h2 className="text-xl font-bold mb-4">EDIT ACHIEVEMENT</h2>
              <div>
                <label className="font-bold">Topic:</label>
                <input type='text' value={topic} onChange={(e) => setTopic(e.target.value)} className="border border-gray-300 rounded px-2 py-1 mb-4 w-full" />
              </div>
              <div>
                <label className="font-bold">Description:</label>
                <input type='text' value={description} onChange={(e) => setDescription(e.target.value)} className="border border-gray-300 rounded px-2 py-1 mb-4 w-full" />
              </div>
              <div className='mb-2'>
                  <label>Type: </label>
                  <select value={type} onChange={(e) => setType(e.target.value)} className={`border  rounded px-2 py-1`}>
                    <option value="">Select Type</option>
                    {achType.map(month => (
                      <option key={month.value} value={month.label}>{month.label}</option>
                    ))}
                  </select>
                  </div>
              <div>
                <label className="font-bold">Image:</label>
                <img src={image} className='w-36 h-36'/>
                <input type='file' onChange={(e) => convertToBase64(e)} accept='/photos/*' className="mb-4 ml-2" />
              </div>
              <div className="flex justify-end">
                <button className="bg-blue-500 text-white px-4 py-2 rounded mr-2" onClick={() => handleEdit(editIndex)}>Save</button>
                <button className="bg-gray-300 text-gray-800 px-4 py-2 rounded" onClick={closeEditPopup}>Cancel</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminAchievements;
