import React, { useEffect } from 'react'
import { CiLocationArrow1, CiPhone } from "react-icons/ci";
import { SlSocialInstagram, SlSocialLinkedin, SlSocialTwitter, SlSocialFacebook, SlSocialYoutube } from "react-icons/sl";
import { RiTwitterXLine } from "react-icons/ri";
import { CiMail } from "react-icons/ci";
import { PiCopyright } from "react-icons/pi";
import Aos from 'aos'
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
const Footer = () => {
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <div className='w-[1200px] md:w-full' data-aos="fade-up">

      <div className="md:flex justify-around h-[2800px] md:h-full  bg-blue-950   text-white p-5 font-[content] text-sm py-10">
        <div className='mt-16 mb-32 md:my-0 '>
          <h1 className="text-[#f42f54] font-serif w-fit text-7xl md:text-2xl mb-16 md:mb-5" data-aos="zoom-in" data-aos-duration='1200'>REACH US</h1>
          <div className='px-16 md:px-0 md:mpx-0'>
            <div className="flex mb-10 md:mb-2">
              <div className="mr-10 md:mr-2">
                <CiLocationArrow1 className='text-9xl md:text-sm' />
              </div>
              <div className='my-auto'>
                <a className="hover:text-green-600 text-5xl md:text-sm  duration-500" href='https://maps.app.goo.gl/oLSpZ53cV2fPZFqU8' target='_blank'>BGS Group of Schools</a>
              </div>
            </div>
            <div className="flex mb-10 md:mb-2">
              <div className="mr-10 md:mr-2">
                <CiPhone className='text-9xl md:text-sm' />
              </div>
              <div className='my-auto'>
                <a className="hover:text-green-600 text-5xl md:text-sm  duration-500" href='tel:9042316003' target='_blank'> 9042316003 / 9042316004</a>
              </div>
            </div>
            <div className="flex mb-10 md:mb-2">
              <div className="mr-10 md:mr-2">
                <CiMail className='text-9xl md:text-sm' />
              </div>
              <div className='my-auto'>
                <a className="hover:text-green-600 text-5xl md:text-sm  duration-500" href='mailto:principalakm@bmhsschool.in' target='_blank'>principalakm@bmhsschool.in</a>
              </div>
            </div>
          </div>
        </div>
        <div className='px-16 md:px-0'>
          <div className="text-[#f42f54] font-serif text-7xl md:text-2xl mb-16 md:mb-5" data-aos="zoom-in" data-aos-duration='1200'>QUICK LINKS</div>
          <div className="grid grid-cols-2 text-5xl md:text-sm">
            <div className="grid">
              <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to='/about'>Our School</Link>
              <a href='https://bgstrust.org/founder' target='_blank' className="mb-16 md:mb-2 hover:text-green-600 duration-500" to=''>Founder</a>
              <a href='https://bgstrust.org/board-of-directors' target='_blank' className="mb-16 md:mb-2 hover:text-green-600 duration-500" to=''>Management</a>
              <a href='https://bgstrust.org/vision-mission' target='_blank' className="mb-16 md:mb-2 hover:text-green-600 duration-500" to=''>Vision and Mission</a>
              <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to='/facilities'>Facilities</Link>
              <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to='/academics#curriculum'>Curriculum</Link>
            </div>
            <div className="grid">
              <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to='/academics#faculty'>Faculties</Link>
              <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to='/academics#sports'>Extra-Curriculum</Link>
              <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to='/public-disclosure'>Public Disclosure</Link>
              <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to='/career'>Career</Link>
              <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to='/achievements'>Awards and Achievements</Link>
              <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to='/gallery'>Gallery</Link>
            </div>

          </div>
        </div>
        <div className='my-16 md:my-0'>
          <h1 className="text-[#f42f54] md:-ml-14 font-serif text-7xl md:text-2xl w-64 mb-16 md:mb-5" data-aos="zoom-in" data-aos-duration='1200'>SCHOOL WORKING HOURS</h1>
          <div className='px-16 md:px-0'>


            <div className="mb-16 text-5xl md:text-sm md:mb-2 ">
              Monday to Saturday: 8AM-5PM
            </div>
            {/* <div className="mb-16 text-5xl md:text-sm md:mb-2 ">

              Saturday: 8AM-5PM
            </div> */}
            <div className="mb-16 text-5xl md:text-sm md:mb-2 ">

              Sunday: Closed
            </div>
          </div>

          <div className='my-16 md:my-0'>
            <h1 className="text-[#f42f54] md:-ml-10  font-serif text-7xl w-fit md:w-36 md:text-2xl my-5 mb-16 md:mb-0" data-aos="zoom-in" data-aos-duration='1200'>FOLLOW US </h1>
            <div className="flex px-16 md:pt-5 md:px-0">
              <div className="mr-16 md:mr-5 ">
                <a href='https://www.instagram.com/bharathidhasanar_schools?igsh=b2ppNTEwcDFpd28y' target='_blank' >

                  <SlSocialInstagram className="text-7xl md:text-2xl hover:text-green-600 duration-500" />
                </a>
              </div>
              <div className="mr-16 md:mr-5">
                <a href='https://www.facebook.com/BMHSSAKM' target='_blank' >

                  <SlSocialFacebook className="text-7xl md:text-2xl hover:text-green-600 duration-500" />
                </a>
              </div>
              <div className="mr-16 md:mr-5">
                <a href='https://youtube.com/@bharathidhasanarmatrichrse5639?si=bDPWCRf2uHMarzgl' target='_blank'>
                  <SlSocialYoutube className="text-7xl md:text-2xl hover:text-green-600 duration-500" />
                </a>
              </div>
              <div className="mr-16 md:mr-5">
                <a href='https://x.com/bgsinstitutions?t=fcHBZt_x-lmYUWPC_MXzDg&s=08 ' target='_blank'>

                  <RiTwitterXLine className=" text-7xl md:text-2xl hover:text-green-600 duration-500" />
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="bg-[rgb(16,23,45)]   text-white font-serif text-sm p-1 px-32 py-10 md:py-0 md:flex justify-around">
        <div className="text-5xl md:text-sm leading-normal my-auto">

          Copyrights © 2024 - All Rights Reserved by BharathiDhasanar Group of Schools.

        </div>
        <div>
          <div className='text-5xl flex md:text-sm  md:my-0'>
            <div className='my-auto'>

              Designed and Developed By :
            </div>
            <div className='flex md:ml-5 my-5 md:my-0 mx-auto cursor-pointer'>
              <a href='https://api.whatsapp.com/send?phone=7010354265' className='flex'>

                <span className='text-8xl md:text-3xl'>𝒁</span>
                <p className='my-auto'>atn.</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer