import React, { useState } from 'react'
import i1 from '../assets/images/bh11.jpeg'
import Slider from '../components/Slider'
import logo from '../assets/images/logo.png'
import Footer from '../components/Footer'
import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
const Contact = () => {
    useEffect(() => {
        AOS.init();
      }, []);
    const contactQuick=[
        {
            title:"Contact",
            link:"contact"
        }
    ]
    const [name,setName]=useState('')
    const [email,setEmail]=useState('')
    const [phone,setPhone]=useState('')
    const [message,setMessage]=useState('')
    const [subject,setSubject]=useState('')

    // submit form
    const handleSubmit=async(e)=>{
        const res=await fetch('https://api.bgsthimiri.com/contact/',{
            method:'POST',
            headers:{'Content-type':'application/json'},
            body:JSON.stringify({name,message,phone,subject,email})
        })
        if(res.ok){
            alert('submited')
        }else{
            alert('something wrong')
        }
    }

  return (
<section class="text-gray-600 body-font relative font-serif w-[1200px] md:w-full">
    <Slider images={[i1]} menuBackgroundImage={logo} interval={5000} title={"CONTACT US"} quicklinks={contactQuick}/>
    <div id='contact' class="container h-[3800px] md:h-full px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap" data-aos="fade-up" data-aos-duration="1200">
        <div class="lg:w-2/3 md:w-1/2 w-[1200px] h-[1400px] my-32 md:my-0 md:h-screen bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-5 md:p-10   md:flex items-end justify-start relative">
            <iframe class="flex md:absolute inset-0 w-full h-[700px]" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.092563427967!2d79.66463057483048!3d13.093319887233324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52bc65fcd2817f%3A0xce314b4dd0ab21ee!2sBharathidasanar%20Matriculation%20Higher%20Sec%20School!5e0!3m2!1sen!2sin!4v1711818472026!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <div class="bg-white relative  flex flex-wrap py-6 rounded shadow-md">
                <div class="lg:w-1/2 px-6 my-10 md:my-0">
                    <h2 class="title-font font-semibold text-gray-900 tracking-widest text-6xl md:text-xs">ADDRESS</h2>
                    <p class="mt-1 text-5xl md:text-sm"> Thimiri, Ranipet District,TamilNadu</p>
                </div>
                <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
                    <h2 class="title-font font-semibold text-gray-900 tracking-widest text-6xl md:text-xs">EMAIL</h2>
                    <a class="text-red-500 leading-relaxed text-5xl md:text-sm">principalakm@bmhsschool.in</a>
                    <h2 class="title-font font-semibold text-gray-900 tracking-widest text-6xl md:text-xs mt-4">PHONE</h2>
                    <p class="leading-relaxed text-5xl md:text-sm">9042316003/9042316004</p>
                </div>
            </div>
        </div>
        <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0"  data-aos="fade-up" data-aos-duration="1200">
            <h2 class="text-gray-900 text-7xl md:text-lg mb-1 font-medium title-font">Feedback</h2>
            <p class="leading-relaxed mb-5 text-5xl md:text-sm text-gray-600">We would love to hear from you,
            </p>
            <div class="relative mb-20 md:mb-4">
                <label for="name" class="leading-7 text-5xl md:text-sm text-gray-600">Name</label>
                <input type="text" onChange={(e)=>setName(e.target.name)} id="name" name="name" class="w-full bg-white rounded h-[100px] md:h-10 border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            </div>
            <div class="relative mb-20 md:mb-4">
                <label for="email" class="leading-7 text-5xl md:text-sm text-gray-600">Email</label>
                <input type="email" onChange={(e)=>setEmail(e.target.value)} id="email" name="email" class="w-full bg-white h-[100px] md:h-10 rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            </div>
            <div class="relative mb-20 md:mb-4">
                <label for="phone" class="leading-7 text-5xl md:text-sm text-gray-600">Phone</label>
                <input type="phone" onChange={(e)=>setPhone(e.target.value)} id="phone" name="phone" class="w-full bg-white h-[100px] md:h-10 rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            </div>
            <div class="relative mb-20 md:mb-4">
                <label for="subject" class="leading-7 text-5xl md:text-sm text-gray-600">Subject</label>
                <input type="subject" onChange={(e)=>setSubject(e.target.value)} id="subject" name="subject" class="w-full h-[100px] md:h-10 bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            </div>
            <div class="relative mb-20 md:mb-4">
                <label for="message" class="leading-7 text-5xl md:text-sm text-gray-600">Message</label>
                <textarea id="message" onChange={(e)=>setMessage(e.target.value)} name="message" class="w-full bg-white rounded border h-[300px]  border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 md:h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
            </div>
            <button onClick={handleSubmit} class="text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 h-20 md:h-[50px] w-64 mx-auto md:w-full md:mx-0 rounded text-4xl md:text-lg">Submit</button>
            {/* <p class="text-xs text-gray-500 mt-3">Chicharrones blog helvetica normcore iceland tousled brook viral
                artisan.</p> */}
        </div>
    </div>
    <div>
        <Footer/>
    </div>
</section>
  )
}

export default Contact