import React, { useEffect, useState } from 'react'
import Sidebar from '../components/sidebar/Sidebar'
import Navbar from '../components/navbar/Navbar'

const AdminContact = () => {
    const [contact,setContact]=useState([])
    const getAll=async()=>{
        try{
            const res=await fetch('https://api.bgsthimiri.com/contact/',{
                method:'GET',
                headers:{'Content-type':'application/json'},
            })
            if(res.status===200){
                const contactArr=await res.json()
                setContact(contactArr)
                console.log(contactArr)
            }else{
                alert('something wrong')
            }
        }catch(err){
            // alert(err)
            console.log(err)
        }
    }

    const [selectedContact, setSelectedContact] = useState(null);

    const openPopup = (contact) => {
        setSelectedContact(contact);
    };

    const closePopup = () => {
        setSelectedContact(null);
    };
    
    useEffect(()=>{
        getAll()
    },[])
  return (
    <div className='w-full flex'>
        <Sidebar/>
        <div className='flex-[6]'>
            <Navbar/>
        <div className="">
            {contact.map((c1, index) => (
                <div key={index} onClick={() => openPopup(c1)} className="cursor-pointer p-2 border-b border-gray-200 hover:bg-gray-100 w-full text-center">
                    {c1.name}
                </div>
            ))}
            {selectedContact && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white p-4 rounded shadow-lg overflow-y-auto max-h-screen w-3/4">
                        <h2 className="text-xl font-bold mb-4">{selectedContact.name}</h2>
                        <p><strong>Email:</strong> {selectedContact.email}</p>
                        <p><strong>Phone:</strong> {selectedContact.phone}</p>
                        <p><strong>Subject:</strong> {selectedContact.subject}</p>
                        <p><strong>Message:</strong> {selectedContact.message}</p>
                        {/* Display other details as needed */}
                        <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mt-4" onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
        </div>
            </div>
    </div>
  )
}

export default AdminContact