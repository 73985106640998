import React, { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar/Sidebar';
import Navbar from '../components/navbar/Navbar';

const AdminFaculty = () => {
  const [editIndex, setEditIndex] = useState(null);
  const [faculty, setFaculty] = useState([]);
  const [name, setName] = useState('');
  const [qualification, setQualification] = useState('');
  const [subject, setSubject] = useState('');
  const [experience, setExperience] = useState('');
  const [level, setLevel] = useState('');
  const [existingFacultiesVisible, setExistingFacultiesVisible] = useState(false);
  const [addFacultyVisible, setAddFacultyVisible] = useState(false);

  const levels = [
    { value: '01', label: 'Primary' },
    { value: '02', label: 'Secondary' },
    { value: '03', label: 'Higher-Secondary' },
  ];

  const toggleExistingFaculties = () => {
    setExistingFacultiesVisible(!existingFacultiesVisible);
  };

  const toggleAddFaculty = () => {
    setAddFacultyVisible(!addFacultyVisible);
  };

  const addFaculty = async () => {
    try {
      const res = await fetch('https://api.bgsthimiri.com/staff/', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ name, qualification, subject, experience, level }),
      });
      if (res.ok) {
        alert('Faculty added successfully');
        getFaculty(); // Refresh faculty list after adding
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Error adding faculty:', error);
    }
  };

  const getFaculty = async () => {
    try {
      const res = await fetch('https://api.bgsthimiri.com/staff/', {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
      });
      if (res.ok) {
        const staffinfo = await res.json();
        setFaculty(staffinfo);
      } else {
        alert('Failed to fetch faculty data');
      }
    } catch (error) {
      console.error('Error fetching faculty:', error);
    }
  };

  // Other functions...
  const deleteFaculty = async (id) => {
    try {
      const res = await fetch('https://api.bgsthimiri.com/staff/' + id, {
        method: 'DELETE',
        headers: { 'Content-type': 'application/json' },
      });
      if (res.ok) {
        alert('Faculty deleted successfully');
        getFaculty(); // Refresh faculty list after deletion
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Error deleting faculty:', error);
    }
  };

  const handleEdit = async (id) => {
    try {
      const res = await fetch('https://api.bgsthimiri.com/staff/' + id, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ name, qualification, experience, subject, level }),
      });
      if (res.ok) {
        alert('Faculty updated successfully');
        getFaculty(); // Refresh faculty list after update
        closeEditPopup();
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Error updating faculty:', error);
    }
  };

  const openEditPopup = (index) => {
    setEditIndex(index);
    const { name, qualification, subject, experience, level } = faculty[index];
    setName(name);
    setQualification(qualification);
    setSubject(subject);
    setExperience(experience);
    setLevel(level);
  };

  const closeEditPopup = () => {
    setEditIndex(null);
    setName('');
    setQualification('');
    setSubject('');
    setExperience('');
    setLevel('');
  };

  useEffect(() => {
    getFaculty();
  }, []);

  return (
    <div className='w-full flex'>
      <Sidebar />
      <div className='flex-[6]'>
        <Navbar />
        <div>
          <div className='text-xl font-bold mb-4 cursor-pointer' onClick={toggleExistingFaculties}>
            Existing Faculties
          </div>
          {existingFacultiesVisible && (
            <div>
              {faculty.map((f1, index) => (
                <div key={index} className='mb-4 border-b pb-4'>
                  {/* Existing faculty elements */}
                  <div className='mb-2'><label className='font-bold'>Name:</label><input value={f1.name} type='text' className='border-[1px] border-gray-300 rounded px-2 py-1 w-64' readOnly /></div>
                <div className='mb-2'><label className='font-bold'>Qualification:</label><input value={f1.qualification} type='text' className='border-[1px] border-gray-300 rounded px-2 py-1 w-64' readOnly /></div>
                <div className='mb-2'><label className='font-bold'>Experience:</label><input value={f1.experience} type='text' className='border-[1px] border-gray-300 rounded px-2 py-1 w-64' readOnly /></div>
                <div className='mb-2'><label className='font-bold'>Subject:</label><input value={f1.subject} type='text' className='border-[1px] border-gray-300 rounded px-2 py-1 w-64' readOnly /></div>
                <div className='mb-2'><label className='font-bold'>Level:</label><input value={f1.level} type='text' className='border-[1px] border-gray-300 rounded px-2 py-1 w-64' readOnly /></div>
                <div className='mt-2'>
                  <button className='bg-blue-500 text-white px-4 py-1 rounded mr-2' onClick={() => openEditPopup(index)}>Edit</button>
                  <button className='bg-red-500 text-white px-4 py-1 rounded' onClick={() => deleteFaculty(f1._id)}>Delete</button>
                </div>
                </div>
              ))}
              {editIndex !== null && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
              <div className="bg-white rounded-lg p-8 max-w-md w-full">
                <div><label>Name:</label><input type='text' value={name} onChange={(e) => setName(e.target.value)} className='border-[1px] border-gray-300 rounded px-2 py-1 w-full' /></div>
                <div><label>Qualification:</label><input type='text' value={qualification} onChange={(e) => setQualification(e.target.value)} className='border-[1px] border-gray-300 rounded px-2 py-1 w-full' /></div>
                <div><label>Experience:</label><input type='text' value={experience} onChange={(e) => setExperience(e.target.value)} className='border-[1px] border-gray-300 rounded px-2 py-1 w-full' /></div>
                <div><label>Subject:</label><input type='text' value={subject} onChange={(e) => setSubject(e.target.value)} className='border-[1px] border-gray-300 rounded px-2 py-1 w-full' /></div>
                <div><label>Level: </label><select value={level} onChange={(e) => setLevel(e.target.value)} className='border-[1px] border-gray-300 rounded px-2 py-1 w-full'>
                  <option value="">Select Level</option>
                  {levels.map((month) => (
                    <option key={month.value} value={month.label}>{month.label}</option>
                  ))}
                </select></div>
                <div className="flex justify-end mt-4">
                  <button className="px-4 py-2 bg-blue-500 text-white rounded mr-2" onClick={() => handleEdit(editIndex)}>Save</button>
                  <button className="px-4 py-2 bg-gray-300 text-gray-800 rounded" onClick={closeEditPopup}>Cancel</button>
                </div>
              </div>
            </div>
          )}
            </div>
          )}
          <div className='text-xl font-bold mb-4 cursor-pointer' onClick={toggleAddFaculty}>
            Add Faculties
          </div>
          {addFacultyVisible && (
            <div>
              <div className='mb-2'><label className='font-bold'>Name:</label><input type='text' onChange={(e) => setName(e.target.value)} className='border-[1px] border-gray-300 rounded px-2 py-1 w-full' /></div>
              <div className='mb-2'><label className='font-bold'>Qualification:</label><input type='text' onChange={(e) => setQualification(e.target.value)} className='border-[1px] border-gray-300 rounded px-2 py-1 w-full' /></div>
              <div className='mb-2'><label className='font-bold'>Experience:</label><input type='text' onChange={(e) => setExperience(e.target.value)} className='border-[1px] border-gray-300 rounded px-2 py-1 w-full' /></div>
              <div className='mb-2'><label className='font-bold'>Subject:</label><input type='text' onChange={(e) => setSubject(e.target.value)} className='border-[1px] border-gray-300 rounded px-2 py-1 w-full' /></div>
              <div className='mb-2'><label className='font-bold'>Level: </label><select onChange={(e) => setLevel(e.target.value)} className='border-[1px] border-gray-300 rounded px-2 py-1 w-full'>
                <option value="">Select Level</option>
                {levels.map((month) => (
                  <option key={month.value} value={month.label}>{month.label}</option>
                ))}
              </select></div>
              <div><input type='submit' value='Add Faculty' onClick={addFaculty} className='px-4 py-2 bg-green-500 text-white rounded cursor-pointer hover:bg-green-600' /></div>
            </div>
          )}
        </div>
      </div>
      
    </div>
  );
};

export default AdminFaculty;
