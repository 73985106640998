import React, { useEffect, useState } from 'react'
import Sidebar from '../components/sidebar/Sidebar'
import Navbar from '../components/navbar/Navbar'
import { Link } from 'react-router-dom'
import ImageContainer from '../components/ImageContainer'

const AdminAdmission = () => {
    const [admissionarr, setAdmissionarr] = useState([])
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [loading, setLoading] = useState(true)
    const openPopup = (student) => {
        setSelectedStudent(student);
    };

    const downloadImage = (base64String, fileName) => {
        const link = document.createElement('a');
        link.href = base64String;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const closePopup = () => {
        setSelectedStudent(null);
    };
    const getAll = async () => {

        try {
            setLoading(true)
            const res = await fetch('https://api.bgsthimiri.com/admission/', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                    "Access-Control-Allow-Origin": "*"
                },
            })
            const admission = await res.json()
            setAdmissionarr(admission)
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        getAll()
    }, [])
    return (
        <div className='w-full flex'>
            <Sidebar />
            <div className='flex-[6]'>
                <Navbar />
                {loading ? (
                    <div className="loading-overlay">
                        <div className="loader"></div>
                    </div>

                ) : (<>
                    <div className="flex flex-wrap ">
                        {admissionarr.map((a1, index) => (
                            <div key={index} className="m-2">
                                <button  onClick={() => openPopup(a1)} className="cursor-pointer bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mt-4">{a1.fullName}</button>
                            </div>
                        ))}
                        {selectedStudent && (
                            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                                <div className="bg-white p-4 rounded shadow-lg overflow-y-auto max-h-screen">
                                    <h2 className="text-xl font-bold mb-4">{selectedStudent.fullName}</h2>
                                    <div className="grid grid-cols-2 gap-x-4">
                                        <div>
                                            <p><strong>Class:</strong> {selectedStudent.classes}</p>
                                            <p><strong>Date of Birth:</strong> {selectedStudent.dateOfBirth}</p>
                                            <p><strong>Mobile Number:</strong> {selectedStudent.mobileNumber}</p>
                                            <p><strong>Gender:</strong> {selectedStudent.gender}</p>
                                            <p><strong>Blood Group:</strong> {selectedStudent.bloodGroup}</p>
                                        </div>
                                        <div>
                                            {/* Display attachments */}
                                            <p><strong>Attachments:</strong></p>
                                            <div className='cursor border-1 border-gray-600 bg-gray' onClick={() => downloadImage(selectedStudent.studentPhoto, 'studentPhoto')}>
                                                <button className='cursor bg-gray-300 p-2 rounded-lg text-gray-800 hover:bg-gray-400 font-bold'>studentPhoto</button>
                                            </div>

                                        </div>
                                    </div>
                                    <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mt-4" onClick={closePopup}>Close</button>
                                </div>
                            </div>
                        )}
                    </div>
                </>
                )}
            </div>
        </div>
    )
}

export default AdminAdmission