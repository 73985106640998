import React, { useState, useEffect } from 'react';
import Sidebar from '../components/sidebar/Sidebar';
import Navbar from '../components/navbar/Navbar';

const AdminEvents = () => {
  const [tab, setTab] = useState('existing'); // State to track which tab is active
  const [eventarr, setEventarr] = useState([]);
  const [editIndex, setEditIndex] = useState(null); 
  const [date, setDate] = useState('');
  const [month, setMonth] = useState('');
  const [event, setEvent] = useState('');
  const [desc, setDesc] = useState('');
  const [image, setImage] = useState('');
  const [errors, setErrors] = useState({});

  const months = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
  ];

  useEffect(() => {
    fetch('https://api.bgsthimiri.com/event/', {
      method: 'GET',
      headers: { 'Content-type': 'application/json' },
    }).then(res => {
      res.json().then(eventInfo => {
        setEventarr(eventInfo);
      });
    });
  }, []);

  const convertToBase64 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.onerror = error => {
        console.log(error);
      };
    }
  };

  const openEditPopup = (index) => {
    setEditIndex(index);
    console.log(eventarr[index])
    const { date, month, title, desc, bg } = eventarr[index];
    setDate(date);
    setMonth(month);
    setEvent(title);
    setDesc(desc);
    setImage(bg);
  };

  const closeEditPopup = () => {
    setEditIndex(null);
    setDate('');
    setMonth('');
    setEvent('');
    setDesc('');
  };

  const deleteEvent = async (id) => {
    const res = await fetch('https://api.bgsthimiri.com/event/' + id, {
      method: 'DELETE',
      headers: { 'Content-type': 'application/json' }
    });
    if (res.ok) {
      alert('Event Deleted Successfully');
    } else {
      alert('Something Went Wrong');
    }
  };

  const handleEdit = async (index) => {
    const res = await fetch('https://api.bgsthimiri.com/event/' + eventarr[index]._id, {
      method: 'PUT',
      crossDomain: true,
      body: JSON.stringify({ date, month, title: event, desc, file: image }),
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        "Access-Control-Allow-Origin": "*"
      }
    });
    if (res.ok) {
      alert('Event Updated Successfully');
    } else {
      alert('Something Went Wrong');
    }
    closeEditPopup();
  };

  const validateForm = () => {
    const errors = {};

    if (!date.trim()) {
      errors.date = "Date is required";
    }

    if (!month.trim()) {
      errors.month = "Month is required";
    }

    if (!event.trim()) {
      errors.event = "Event name is required";
    }

    if (!desc.trim()) {
      errors.desc = "Description is required";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const addEvent = async () => {
    const check = await validateForm();
    if (check) {
      const res = await fetch('https://api.bgsthimiri.com/event/', {
        method: 'POST',
        crossDomain: true,
        body: JSON.stringify({ date, month, title: event, desc, file: image }),
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
          "Access-Control-Allow-Origin": "*"
        }
      });
      if (res.ok) {
        alert('Event Added Successfully');
      } else {
        alert('Something Went Wrong');
      }
    } else {
      alert("Fill all the fields");
      setErrors({});
    }
  };

  return (
    <div className='w-full flex'>
      <Sidebar />
      <div className='flex-[6]'>
        <Navbar />
        <div>
          <div className="flex">
            <button
              className={`tab-button ${tab === 'existing' ? 'active' : ''}`}
              onClick={() => setTab('existing')}
            >
              Existing Events
            </button>
            <button
              className={`tab-button ${tab === 'add' ? 'active' : ''}`}
              onClick={() => setTab('add')}
            >
              Add Event
            </button>
          </div>
          {tab === 'existing' && (
            <div>
              {eventarr.map((e1, index) => (
                <div key={index} className='mb-4'>
                  {/* Your existing event display code */}
                  <div>
                <label>Date: </label>
                <input type='text' value={e1.date} readOnly />
              </div>
              <div>
                <label>Month: </label>
                <input type='text' value={e1.month} readOnly />
              </div>
              <div>
                <label>Event name: </label>
                <input type='text' value={e1.title} readOnly />
              </div>
              <div>
                <label>Description: </label>
                <textarea value={e1.desc} readOnly />
              </div>
              <div>
                <button onClick={() => openEditPopup(index)} className='bg-blue-500 text-white px-4 py-1 rounded mr-2'>Edit</button>
                <button onClick={() => deleteEvent(e1._id)} className='bg-red-500 text-white px-4 py-1 rounded'>Delete</button>
              </div>
                </div>
              ))}
            </div>
          )}
          {tab === 'add' && (
            <div>
              <h1 className='mb-2'>Add New Events</h1>
              <div className='flex flex-col'>
                <div className='mb-2'>
                  <label>Date: </label>
                  <input type='text' onChange={(e) => setDate(e.target.value)} className={`border ${errors.date ? 'border-red-500' : 'border-gray-300'} rounded px-2 py-1`} />
                  {errors.date && <span className='text-red-500'>{errors.date}</span>}
                </div>
                <div className='mb-2'>
                  <label>Month: </label>
                  <select value={month} onChange={(e) => setMonth(e.target.value)} className={`border ${errors.month ? 'border-red-500' : 'border-gray-300'} rounded px-2 py-1`}>
                    <option value="">Select Month</option>
                    {months.map(month => (
                      <option key={month.value} value={month.label}>{month.label}</option>
                    ))}
                  </select>
                  {errors.month && <span className='text-red-500'>{errors.month}</span>}
                </div>
                <div className='mb-2'>
                  <label>Event name: </label>
                  <input type='text' onChange={(e) => setEvent(e.target.value)} className={`border ${errors.event ? 'border-red-500' : 'border-gray-300'} rounded px-2 py-1`} />
                  {errors.event && <span className='text-red-500'>{errors.event}</span>}
                </div>
                <div className='mb-2'>
                  <label>Description: </label>
                  <textarea onChange={(e) => setDesc(e.target.value)} className={`border ${errors.desc ? 'border-red-500' : 'border-gray-300'} rounded px-2 py-1`} />
                  {errors.desc && <span className='text-red-500'>{errors.desc}</span>}
                </div>
                <div className='mb-2'>
                  <label>Upload Image:</label>
                  <input type='file' accept='image/*' onChange={convertToBase64} className='border border-gray-300 rounded px-2 py-1' />
                </div>
                <div>
                  <button onClick={addEvent} className='px-4 py-2 bg-green-500 text-white rounded cursor-pointer hover:bg-green-600'>Add Event</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Edit Popup */}
      {editIndex !== null && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white rounded-lg p-8 max-w-md w-full">
            <div>
              <label>Date: </label>
              <input type='text' value={date} onChange={(e) => setDate(e.target.value)} className='border border-gray-300 rounded px-2 py-1 mb-2 w-full' />
            </div>
            <div>
              <label>Month: </label>
              <select value={month} onChange={(e) => setMonth(e.target.value)} className='border border-gray-300 rounded px-2 py-1 mb-2 w-full'>
                <option value="">Select Month</option>
                {months.map(month => (
                  <option key={month.value} value={month.label}>{month.label}</option>
                ))}
              </select>
            </div>
            <div>
              <label>Event name: </label>
              <input type='text' value={event} onChange={(e) => setEvent(e.target.value)} className='border border-gray-300 rounded px-2 py-1 mb-2 w-full' />
            </div>
            <div>
              <label>Description: </label>
              <textarea value={desc} onChange={(e) => setDesc(e.target.value)} className='border border-gray-300 rounded px-2 py-1 mb-2 w-full' />
            </div>
            <div>
              <img className='w-36 h-36' src={image} />
            </div>
            <div>
              <label>Upload Image:</label>
              <input type='file' accept='image/*' onChange={convertToBase64} className='border border-gray-300 rounded px-2 py-1 mb-2 w-full' />
            </div>
            <div className="flex justify-end mt-4">
              <button onClick={() => handleEdit(editIndex)} className="px-4 py-2 bg-blue-500 text-white rounded mr-2">Save</button>
              <button onClick={closeEditPopup} className="px-4 py-2 bg-gray-300 text-gray-800 rounded">Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminEvents;
