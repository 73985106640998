import React from 'react'
import Slider from './Slider'
import i1 from '../assets/images/bh1.jpeg'
import i2 from '../assets/images/bh2.jpeg'
import i3 from '../assets/images/bh3.jpeg'
import i4 from '../assets/images/bh4.jpeg'
import i5 from '../assets/images/backG.jpeg'
import logo from '../assets/images/logo.png'
import vision from '../assets/images/vision.jpeg'
import Events from './Events'
import Footer from './Footer'
import Underline from './Underline'
import CountUp from 'react-countup';
import Parallax from './Parallax'
import para from '../assets/images/para.jpeg'
import { IoMdPaper } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
const Header = () => {

  useEffect(() => {
    AOS.init();
  }, []);
  const images = [i1, i2, i3, i4,]
  const homeQuick = [
    {
      title: "Welcome",
      link: "welcome"
    },
    {
      title: "Our Vision",
      link: "vision"
    },
    {
      title: "Our Mission",
      link: "mission"
    },

  ]
  return (
    <div className='w-[1200px] md:w-full'>
      <div className=''>

        <Slider images={images} title={"BHARATHIDHASANAR PUBLIC SCHOOL - THIMIRI"} menuBackgroundImage={logo} quicklinks={homeQuick} interval={5000}></Slider>
      </div>
      <div className="bg-yellow-400 text-white text-center text-5xl md:text-xl  md:w-full h-full md:h-16 py-16 leading-normal md:py-4 tracking-wider"  >
        Empowering Minds, Igniting Futures, Where Learning Takes Flights.
      </div>
      <div className="h-full w-[1200px] md:w-full bg-blue-950" style={{
        backgroundImage: `linear-gradient(rgba(23,37,84,0.8),rgba(23,37,84,0.8)),url(${i5})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // backgroundPositionX:'550px',
        // backgroundPositionY:'100px'
      }}>
        <div id='welcome' className="px-16 md:px-32 lg:px-[450px] h-[2000px] md:h-full sm:text-2xl sm:leading-loose text-5xl lg:text-sm text-center py-32 font-semibold text-white leading-loose lg:leading-10 tracking-wide" data-aos='fade-up' data-aos-duration='1000' >
          <div>

            BGS  has been a beacon of education and a symbol of hope since 1980. As the first school founded in this vibrant town, we take pride in our rich legacy of nurturing young minds and guiding them towards a bright future. Established with a vision to provide quality education and holistic development, BGS has been a trusted institution for generations of families and beyond.

            At BGS, Our mission is to empower every student with knowledge, skills, and values that prepare them to thrive in a rapidly changing world.
          </div>
          <div className="text-center py-32 md:py-5">
            <Link to='/about'> <button className="border-2 p-1 px-10 md:px-5  font-bold hover:-translate-y-1 duration-500 border-yellow-400 bg-white text-black rounded  hover:bg-yellow-400 hover:text-white  ">Learn More </button></Link>
          </div>
        </div>
      </div>
      <div className="my-16  md:w-full">
        <div className="md:text-5xl text-9xl text-blue-950 text-center mt-32 md:mt-16  font-bold" data-aos='zoom-in' data-aos-duration='1000' >
          EVENTS
          <Underline />
        </div>
        <Events />
        <div className="text-right mr-16">
          <Link to='/gallery' className="border-2 md:p-2 md:px-5 px-10 p-5 text-5xl md:text-sm  font-bold hover:-translate-y-1   duration-500 border-yellow-400 bg-white text-black rounded  hover:bg-yellow-400 hover:text-white  ">View All</Link>
        </div>
      </div>
      <div id='vision' className="bg-gray-200 shadow-2xl  md:h-full  md:w-full" data-aos='fade-up' >
        <div className="md:text-5xl text-9xl text-blue-950 text-center pt-16  font-bold" data-aos='zoom-in' data-aos-duration='1000' >
          OUR VISION
          <Underline />
        </div>
        <div className="lg:flex py-16 mx-10 lg:mx-32 " >
          <div className="text-justify leading-normal text-5xl md:text-xl lg:text-[17px] mr-16" data-aos='fade-right' data-aos-duration='1000'  >
            <div className=''>


              Our school embodies innovation, excellence, and compassion, fostering a diverse and curious learning community. We aim to develop well-rounded individuals with the skills and values to thrive in a changing world. Committed to integrity, empathy, and collaboration, we ensure every student feels valued and inspired. We nurture lifelong learners who are academically strong, socially responsible, and environmentally aware, ready to tackle future challenges confidently and creatively.
            </div>
            <div>
              <div className="mt-5 text-green-600 mb-2 text-6xl md:text-2xl font-semibold">
                OUR CORE PRINCIPLES
              </div>
              <div className="ml-10">
                <ul className="list-disc">
                  <li>We prioritize holistic growth by integrating academics, arts, athletics, and social-emotional learning, fostering resilience and adaptability.</li>
                  <li>We celebrate diversity and promote equity, tailoring teaching to diverse needs and nurturing a culture of respect.</li>
                  <li>We cultivate critical thinking, problem-solving, and creativity through technology and innovation, preparing students for a dynamic world.</li>

                </ul>
              </div>
            </div>
          </div>
          <div className="my-auto " data-aos='zoom-in' data-aos-duration='1000' >
            <img className='md:w-[50%] mx-auto  my-10 lg:w-[4000px] rounded-lg' src={i1} />
          </div>
        </div>
      </div>
      <div id='mission' className=" md:w-full md:h-full">
        <div className="text-9xl md:text-5xl text-blue-950 text-center pt-16  font-bold" data-aos='zoom-in' data-aos-duration='1000' >
          OUR MISSION
          <Underline />
        </div>
        <div className="lg:flex py-16 mx-10 lg:mx-32">
          <div className="my-auto" data-aos='fade-right' data-aos-duration='1000' >
            <img className='md:w-[50%] mx-auto  my-10  lg:w-[3800px] rounded-lg' src={vision} />
          </div>
          <div className="text-justify leading-normal text-5xl md:text-xl lg:text-[17px] lg:ml-16" data-aos='zoom-in' data-aos-duration='1000' >
            <div>
              Our mission is to provide an inclusive, nurturing environment where every student reaches their full potential. We value and support each individual, fostering intellectual curiosity, emotional resilience, social responsibility, and physical health. Committed to excellence, innovation, and collaboration, we empower students to thrive in a global society, equipped with the skills and values to lead with confidence and compassion.
            </div>
            <div>
              <div className="mt-5 text-green-600 mb-2 text-6xl md:text-2xl font-semibold">
                OUR CORE PRINCIPLES
              </div>
              <div className="ml-10">
                <ul className="list-disc">
                  <li>We promote holistic growth through a balanced curriculum of academics, arts, athletics, and social-emotional learning, encouraging passion exploration.</li>
                  <li>We embrace diversity and ensure equity by creating a supportive environment where all are valued, with teaching tailored to diverse needs.</li>
                  <li>We foster critical thinking, problem-solving, and creativity through technology, enhancing learning and cultivating innovation.</li>


                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className='h-[350vh] md:h-full'>
        <Parallax image={para} gradient="rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)">
          <div>
            <h2 className='text-white mx-32 text-9xl md:text-5xl leading-normal italic'>
              “Education is the most powerful weapon which you can use to change the world.”
            </h2>
            <p className='ml-[50%] md:text-3xl text-7xl mt-32 md:mt-10'>- Nelson Mandela</p>
          </div>
        </Parallax>
      </div>
      <div className="my-16 shadow-2xl h-[1300px] md:h-full md:w-full bg-gray-200 py-16 ">
        <div className="text-9xl md:text-5xl  text-center font-bold text-blue-950" data-aos='zoom-in' data-aos-duration='1000'  >
          BMHSS AT A GLANCE
          <Underline />
        </div>
        <div className="md:flex grid grid-cols-2 justify-around mt-16  md:mx-16" id="glance">
          <div className="md:w-52 mt-16 md:mt-0">
            <CountUp start={0} end={100} delay={0} enableScrollSpy={true}>
              {({ countUpRef }) => (
                <div className='flex justify-center items-center'>
                  <span className="text-center text-9xl md:text-7xl mb-10 text-green-600" ref={countUpRef} /><div className='text-center text-9xl md:text-7xl mb-10 text-green-600'>%</div>
                </div>
              )}
            </CountUp>

            <div className="text-5xl md:text-sm text-center">Academic Results in secondary and higher secondary classes</div>
          </div>
          <div className="md:w-52 mt-16 md:mt-0">
            <CountUp start={0} end={26} delay={0} enableScrollSpy={true}>
              {({ countUpRef }) => (
                <div className='flex justify-center items-center'>
                  <span className="text-center text-9xl md:text-7xl mb-10 text-green-600" ref={countUpRef} />
                </div>
              )}
            </CountUp>
            <div className="text-5xl md:text-sm text-center">acres ofen and spacious infrastructure</div>
          </div>
          <div className="md:w-52 mt-16 md:mt-0">
            <CountUp start={0} end={25} delay={0} enableScrollSpy={true}>
              {({ countUpRef }) => (
                <div className='flex justify-center items-center'>
                  <span className="text-center text-9xl md:text-7xl mb-10 text-green-600" ref={countUpRef} /><div className='text-center text-9xl md:text-7xl mb-10 text-green-600'>+</div>
                </div>
              )}
            </CountUp>
            <div className="text-5xl md:text-sm text-center">Co-curricular and Extra-curricular activities for students</div>
          </div>
          <div className="md:w-52 mt-16 md:mt-0">
            <CountUp start={0} end={500} delay={0} enableScrollSpy={true}>
              {({ countUpRef }) => (
                <div className='flex justify-center items-center'>
                  <span className="text-center text-9xl md:text-7xl mb-10 text-green-600" ref={countUpRef} /><div className='text-center text-9xl md:text-7xl mb-10 text-green-600'>+</div>
                </div>
              )}
            </CountUp>
            <div className="text-5xl md:text-sm text-center">Students enrolled during the first year</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header