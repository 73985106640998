import React, { useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import curriculumarr from "./curriculumarr";

const PageCover = React.forwardRef((props, ref) => {

  return (
    <div
      className={"page page-cover page-cover-" + props.pos}
      ref={ref}
      data-density="hard"
    >
      <div className="text-center my-[50%] font-serif text-5xl md:text-3xl">
        <h2>CURRICULUM</h2>
      </div>
    </div>
  );
});

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="page" ref={ref} data-density={props.density | "soft"}>
      <div className="font-serif">
        <h2 className="text-center my-10 text-5xl md:text-2xl"> {props.topic}</h2>
        <div
          className="page-image"
          style={{ backgroundImage: `url(images/html/${props.image})` }}
        ></div>
        <div className={`${props.topic==''?'my-[50%] mx-5 text-4xl md:text-3xl':'text-4xl md:text-xl'}  text-center`} dangerouslySetInnerHTML={{ __html: props.children }}></div>
        <div className="page-footer absolute bottom-0 text-center left-0 w-full">{props.number}</div>
      </div>
    </div>
  );
});

function Curriculum() {
  const flipBookRef = useRef(null);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [orientation, setOrientation] = useState("landscape");
  const [state, setState] = useState("read");

  useEffect(() => {
    const pageFlipInstance = () => {
      if (flipBookRef.current) {
        const pageFlipInstance = flipBookRef.current.pageFlip();
        if (pageFlipInstance) {
          setTotalPage(pageFlipInstance.getPageCount());
          setPage(pageFlipInstance.getCurrentPageIndex());
        }
      }
    };

    pageFlipInstance();
  }, []);

  const nextButtonClick = () => {
    const pageFlipInstance = flipBookRef.current.pageFlip();
    if (pageFlipInstance) {
      pageFlipInstance.flipNext();
    }
  };

  const prevButtonClick = () => {
    const pageFlipInstance = flipBookRef.current.pageFlip();
    if (pageFlipInstance) {
      pageFlipInstance.flipPrev();
    }
  };

  const onPage = (e) => {
    setPage(e.data);
  };

  const onChangeOrientation = (e) => {
    setOrientation(e.data);
  };

  const onChangeState = (e) => {
    setState(e.data);
  };
  const [wid,setWid]=useState(window.screen.width)
  useEffect(()=>{
    setWid(window.screen.width)
  },[window.screen.width])

  return (
    <div className=" font-serif">

      <div className="container-md mx-[25%] py-16" style={{ position: "relative" }}>
        <HTMLFlipBook
          width={wid < 800 ? 550 : 350}
          // width={350}
          // height={550}
          height={wid < 800 ? 950 : 550}
          maxShadowOpacity={0.5}
          showCover={true}
          mobileScrollSupport={true}
          onFlip={onPage}
          onChangeOrientation={onChangeOrientation}
          onChangeState={onChangeState}
          className="flip-book html-book demo-book "
          ref={flipBookRef}
        >
          <PageCover key={0} pos="top" />
          {curriculumarr.map((c1,i) => (
            <Page key={i + 1} image={`${i % 8 + 1}.jpg`} topic={c1.topic} number={`${i+1}`}>
              {c1.content}
            </Page>
          ))}
          <PageCover key={101} pos="bottom">
            THE END
          </PageCover>
        </HTMLFlipBook>
      </div>
            
      
    </div>
  );
}

export default Curriculum;
