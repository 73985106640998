import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const Admin = () => {
    const redirect=useNavigate()
    const checkAdmin=async()=>{
        try{
            const res=await fetch('https://api.bgsthimiri.com/user/',{
                method:'GET',
                credentials:'include',
                headers:{'Content-type':'application/json'}
            })
            if(res.status==400){
                redirect('/admin/login')
            }
        }catch(err){
            console.log(err)
        }
    }
    useEffect(()=>{
        checkAdmin()
    },[])
  return (
    <div>
        <Outlet/>

    </div>
  )
}

export default Admin