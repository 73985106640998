export const career=[
    {
        topic:"PGT",
        answer:"The salary for this position ranges from Rs. 20,000 to Rs. 45,000, with accommodation available. We trust your skills and potential.",
    },
    {
        topic:"TGT",
        answer:"The salary for this position ranges from Rs. 15,000 to Rs. 30,000, with accommodation available. We trust your skills and potential.",
    },
    {
        topic:"PRT",
        answer:"The salary for this position ranges from Rs. 12,000 to Rs. 20,000, with accommodation available. We trust your skills and potential.",
    },
]