import React, { useState,useEffect } from 'react'

const Events = () => {
    const [eventarr, setEventarr] = useState([]);

  useEffect(() => {
    const storedEvents = sessionStorage.getItem('eventarr');
    if (storedEvents) {
      setEventarr(JSON.parse(storedEvents));
    } else {
      fetch('https://api.bgsthimiri.com/event/', {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
      })
        .then(res => res.json())
        .then(eventInfo => {
          setEventarr(eventInfo);
          sessionStorage.setItem('eventarr', JSON.stringify(eventInfo));
        });
    }
  }, []);
  return (
    <div class="max-w-screen-xl mx-auto p-5 my-16 md:my-0 sm:p-10 md:p-16 -z-50">
    <div class="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 gap-5" data-aos="fade-up" data-aos-duration="1500">
        {eventarr.map(e1=>(

            <div class="relative w-full flex items-end md:h-[450px] justify-start text-left bg-cover bg-center"
            style={{backgroundImage:`url(${e1.bg})`}}
            // style="height: 450px; background-image:url(https://media.gettyimages.com/photos/at-the-the-network-tolo-televised-debate-dr-abdullah-abdullah-with-picture-id1179614034?k=6&amp;m=1179614034&amp;s=612x612&amp;w=0&amp;h=WwIX3RMsOQEn5DovD9J3e859CZTdxbHHD3HRyrgU3A8=);"
            >
                <img className='w-full h-full' src={e1.bg}/>
            <div class="absolute top-0 mt-20 right-0 bottom-0 left-0 bg-gradient-to-b from-transparent to-gray-900">
            </div>
            <div class="absolute top-0 right-0 left-0 mx-5 mt-2 flex justify-between items-center">
                <p 
                    class="md:text-xs text-5xl bg-blue-950 text-white px-5 py-2 uppercase hover:bg-white hover:text-blue-950 transition ease-in-out duration-500">{e1.title}</p>
                <div class="text-white text-5xl  md:text-xs font-regular flex flex-col justify-start">
                    <span class="text-7xl md:text-3xl leading-10 font-semibold">{e1.date}</span>
                    <span class="mt-5 md:mt-0">{e1.month}</span>
                </div>
            </div>
            <div class="p-5 absolute">
                <p 
                    class="md:text-[15px] text-4xl tracking-tight font-medium leading-7 font-regular text-white hover:underline">
                    {e1.desc}
                </p>
            </div>

        </div>

))}
        

    </div>
</div>
  )
}

export default Events