import React from 'react'
import discoverarr from './sports'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
const DiscoverMore = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mx-auto mb-10">

    {discoverarr.map((d1,index)=>(
        <div style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1)),url(${d1.image})`,
            backgroundRepeat:'no-repeat',
            backgroundSize:'cover'

          }} className="w-[1000px] h-[500px]  md:w-96 md:h-60 overflow-hidden hover:scale-90 duration-500 cursor-pointer rounded-lg"
          data-aos={index % 2 === 0 ? 'fade-left' : 'fade-right'}
          data-aos-offset="200">
            <div className="hover:-translate-y-20 ease-out duration-500">
              <div className="text-white text-7xl md:text-3xl drop-shadow-[2px_2px_black]  text-center pt-40">

{d1.topic}
              </div>
              {/* <div className="text-white text-center mt-10"><Link to={`${d1.link}`}>Learn more</Link></div> */}
              </div>

          </div>
    ))}
    </div>
  )
}

export default DiscoverMore