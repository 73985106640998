import React from 'react';

const Modal = ({ isOpen, onClose, career }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="relative z-50 w-full max-w-4xl mx-auto my-6">
        <div className="bg-white rounded-lg shadow-lg">
          <div className="p-6">
            <div className="font-bold mb-4 text-lg">{career.student.firstname}'s Details</div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="font-semibold">First Name:</label>
                <span>{career.student.firstname}</span>
              </div>
              <div>
                <label className="font-semibold">Last Name:</label>
                <span>{career.student.lastname}</span>
              </div>
              <div>
                <label className="font-semibold">Date of Birth:</label>
                <span>{career.student.dob}</span>
              </div>
              <div>
                <label className="font-semibold">Age:</label>
                <span>{career.student.age}</span>
              </div>
              <div>
                <label className="font-semibold">Gender:</label>
                <span>{career.student.gender}</span>
              </div>
              <div>
                <label className="font-semibold">Email:</label>
                <span>{career.student.email}</span>
              </div>
              <div>
                <label className="font-semibold">Experience:</label>
                <span>{career.student.experience}</span>
              </div>
              <div>
                <label className="font-semibold">Previous Employer:</label>
                <span>{career.student.prevemployer}</span>
              </div>
              <div>
                <label className="font-semibold">Address:</label>
                <span>{career.student.address}</span>
              </div>
              <div>
                <label className="font-semibold">City:</label>
                <span>{career.student.city}</span>
              </div>
              <div>
                <label className="font-semibold">Region:</label>
                <span>{career.student.region}</span>
              </div>
              <div>
                <label className="font-semibold">Pin Code:</label>
                <span>{career.student.postal}</span>
              </div>
              <div>
                <label className="font-semibold">Position:</label>
                <span>{career.student.position}</span>
              </div>
              <div>
                <label className="font-semibold">Preferred City:</label>
                <span>{career.student.preferredcity}</span>
              </div>
              <div>
                <label className="font-semibold">How did you hear:</label>
                <span>{career.student.howheard}</span>
              </div>
              {/* Add other fields similarly */}
            </div>
          </div>
          <div className="p-4 bg-gray-100 text-right">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
