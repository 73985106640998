import React, { useState } from 'react'
import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
const AchievementBox = ({topic,desc,image,type}) => {
    const [showAnswer, setShowAnswer] = useState(false);
    useEffect(() => {
      AOS.init();
    }, []);
  return (
  
    <>
      <div className="border border-gray-400 rounded-lg " data-aos="fade-up" >
        <article className="flex items-center  justify-between px-5 bg-blue-950 text-white ">
          <h2
            className="cursor-pointer text-7xl md:text-xl lg:text-lg my-auto p-2 py-16 md:py-5"
            onClick={() => setShowAnswer(!showAnswer)}
          >
            {topic}
          </h2>
          <ul>
            {!showAnswer && (
              <li className='text-7xl md:text-sm'>
                <button onClick={() => setShowAnswer(true)}>
                  +
                </button>
              </li>
            )}
            {showAnswer && (
              <li className='text-7xl md:text-sm'>
                <button onClick={() => setShowAnswer(false)}>
                  -
                </button>
              </li>
            )}
          </ul>
        </article>

        <article
          className={`${showAnswer && "border-t border-gray-400 p-4 lg:p-6"}`}
        >
          {showAnswer &&<div className="md:flex"><img className="md:w-[300px] mr-10" src={image}/> <p className='text-5xl md:text-sm my-16 md:my-0'>{desc}</p></div>}
        </article>
      </div>
    </>

  )
}

export default AchievementBox