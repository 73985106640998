import React from 'react'

const Underline = () => {
  return (
//     <div className="border-b-2 w-20 mt-5 border-blue-950 mx-auto">

//   </div>
<div className="relative mt-3">
      <div className="absolute flex bottom-0 left-1/2 transform -translate-x-1/2">
        <div className="border-b-2 md:border-b-[1px] md:mt-1 mt-2  border-black w-64 md:w-16 mr-5 md:mr-1"></div>
        <div className="md:w-2 w-5 h-5 md:h-2 bg-transparent border-2 md:border-[1px]   border-black transform rotate-45 absolute top-0 left-1/2 -translate-x-1/2"></div>
        <div className="border-b-2 md:border-b-[1px]  border-black w-64 md:w-16 ml-1"></div>
        
      </div>
    </div>
  )
}

export default Underline