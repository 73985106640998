import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { Link } from "react-router-dom";

const Widget = ({ type }) => {
  let data;

  //temporary
  const amount = 100;
  const diff = 20;

  switch (type) {
    case "event":
      data = {
        title: "Events",
        isMoney: false,
        link: "/admin/event",

      };
      break;
    case "faculty":
      data = {
        title: "Faculty",
        isMoney: false,
       link: "/admin/faculty",
      };
      break;
    case "achievement":
      data = {
        title: "Achievement",
        link: "/admin/achievement",

      };
      break;
    case "gallery":
      data = {
        title: "Gallery",
        link: "/admin/gallery",
      };
      break;
    case "admission":
      data = {
        title: "Admission",
        link: "/admin/admission",
      };
      break;
    case "contact":
      data = {
        title: "Contact",
        link: "/admin/contact",
      };
      break;
    case "career":
      data = {
        title: "Career",
        link: "/admin/career",
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "$"} {amount}
        </span>
        <span className="link"><Link to={data.link}>Open</Link></span>
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon />
          {diff} %
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
