import React, { useState } from 'react'
import Slider from '../components/Slider'
import i2 from '../assets/images/bh2.jpeg'
import logo from '../assets/images/logo.png'
import Underline from '../components/Underline'
import Curriculum from '../components/Curriculum'
import Faculty from '../components/Faculty'
import sports from '../components/sports'
import Footer from '../components/Footer'
import wood from '../assets/images/table.jpeg'
import black from '../assets/images/blackboard.jpeg'
import ImageHoverText from '../components/ImageHoverText'
import curriculum from '../assets/images/curriculum.jpeg'
import DiscoverMore from '../components/DiscoverMore'
import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
const Academics = () => {
  useEffect(() => {
    setTimeout(() => {
      AOS.init({ duration: 1500 });
    }, 100);
  }, []);
const [topic,setTopic]=useState(sports[0].topic)
const [story,setStory]=useState(sports[0].story)
const [image,setImage]=useState(sports[0].image)
    const academicQuick=[
        {
            title:"Curriculum",
            link:"curriculum"
        },
        {
            title:"Our Faculty",
            link:"faculty"
        },
        {
            title:"Extra-Curriculum",
            link:"sports"
        },
    ]
  return (
    <div className='w-[1200px] md:w-full'>
        <Slider images={[i2]} menuBackgroundImage={logo} interval={5000} title={"ACADEMICS"} quicklinks={academicQuick}/>
        <div className="bg-gray-200 h-[2400px] md:h-full py-72 md:py-0" id="curriculum" >
        <div className="text-9xl md:text-5xl text-blue-950 text-center pt-16 pb-16 md:pb-5  font-bold" data-aos='zoom-in' data-aos-duration='1000'>
        CURRICULUM
        <Underline/>
      </div>
      <div  className='mt-48 md:mt-0' style={{ backgroundImage: `url(${wood})`, backgroundSize: 'cover', backgroundPosition: 'center', height: 'max-content', width: '100%' }}>
        <Curriculum/>
      </div>
        </div>
        <div className='flex flex-col text-5xl md:text-lg text-justify leading-normal md:flex-row mt-32 mx-16 px-16 border-[1px] bg-[#f42f54] text-white py-10 border-black rounded-3xl shadow-2xl'data-aos='zoom-in-down' data-aos-duration='1000'  >
          <div className='my-auto'>
            <ul className='list-disc '>
              <li className='my-4'>We offer a comprehensive and well-rounded curriculum designed to nurture the intellectual, emotional, and physical development of students from pre-KG to 12th grade.</li>
              <li className='my-4'>Our curriculum is meticulously designed to nurture the intellectual, emotional, and physical development of every student, ensuring they receive a holistic education that prepares them for success in all areas of life.</li>
              <li className='my-4'>We believe in instilling a love of learning in our students, encouraging them to explore their interests and passions while developing critical thinking skills.</li>
              <li className='my-4'>We continuously update and enhance our curriculum to align with the latest educational trends, best practices, and technological advancements, providing students with a competitive edge in today's rapidly evolving world.</li>
            </ul>
          </div>
          <div className='blob'>
            {/* <img className='rounded-2xl' src={curriculum}/> */}
          </div>
        </div>
        <div className=' md:h-full py-32 md:py-0' id="faculty">
        <div className="text-9xl md:text-5xl text-blue-950 text-center pt-16  font-bold" data-aos='zoom-in' data-aos-duration='1000' >
        FACULTIES
        <Underline/>
      </div>
      <div className="my-32 md:mt-16">
        <div className='flex flex-col md:flex-row  justify-around'>
          <div className='border-8 md:border-4 mx-auto text-center border-yellow-500 border-dotted  w-[900px] h-[280px] my-10 md:my-0 md:w-[400px] md:h-[180px]  rounded-xl py-5'  data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1000">
            <div><h2 className='text-7xl md:text-3xl'>PRIMARY</h2></div>
            <button >  <div className='mt-10  border-[1px] px-10 py-2 w-fit bg-[#f42f54] text-white text-5xl md:text-xl mx-auto'>View</div></button>
          </div>
          <div className='border-8 md:border-4 mx-auto text-center border-yellow-500 border-dotted w-[900px] h-[280px] my-10 md:my-0 md:w-[400px] md:h-[180px]  rounded-xl py-5' data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1000">
            <div><h2 className='text-7xl md:text-3xl'>SECONDARY</h2></div>
            <button > <div className='mt-10  border-[1px] px-10 py-2 w-fit  mx-auto bg-[#f42f54] text-5xl md:text-xl text-white'>View</div></button>
          </div>
          <div className='border-8 md:border-4 mx-auto text-center border-yellow-500 border-dotted w-[900px] h-[280px] my-10 md:my-0 md:w-[400px] md:h-[180px]  rounded-xl py-5' data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1000" >
            <div><h2 className='text-7xl md:text-3xl'>HIGHER-SECONDARY</h2></div>
            <button > <div className='mt-10  border-[1px] px-10 py-2 w-fit bg-[#f42f54] text-white text-5xl md:text-xl mx-auto'>View</div></button>
          </div>
        </div>
        </div>
      </div>
      <div className='bg-gray-200  md:h-full' id="sports" data-aos='fade-up' data-aos-duration='1000' >
      <div className="text-9xl md:text-5xl text-blue-950 text-center pt-16  font-bold" data-aos='zoom-in' data-aos-duration='1000' >
        EXTRA CURRICULUM
        <Underline/>
      </div>
      {/* <div className='lg:flex mx-10 lg::mx-32 py-16'>
        <div className='h-[800px] md:h-[360px]  lg:w-[140%] mb-10 lg:mb-0 shadow-2xl overflow-x-hidden border-[0.5px] bg-white text-blue-950 border-blue-950 hide-scroll-bar  overflow-scroll'>
          {sports.map(s1=>(
            <div className='mx-auto hover:bg-gray-200 duration-500 text-center text-5xl md:text-2xl cursor-pointer  border-b-[0.5px] border-blue-950 py-10 md:py-5' onClick={()=>{
              setTopic(s1.topic)
              setStory(s1.story)
              setImage(s1.image)
            }}>{s1.topic}</div>
          ))}
        </div>
        <div className='md:flex text-2xl md:text-xl lg:text-lg '>
          <div className='text-center w-fit'>
          <h1 className="text-8xl md:hidden my-16">{topic}</h1>
          </div>
          <div className='my-16 mt-24 lg:my-auto mr-10 lg:ml-10'>
          <img className="h-full" src={image}/>
          </div>
          <div>
            <h1 className=" md:text-3xl hidden w-fit md:flex mb-10 mt-5">{topic}</h1>
            <p className='text-5xl md:text-sm leading-normal text-justify'>

            {story}
            </p>
          </div>
        </div>
      </div> */}
     {/* <div className="container mx-auto p-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {sports.map((sport, index) => (
          <div key={index} className="flex justify-center ">
            <ImageHoverText image={sport.image} hoverText={sport.topic} />
          </div>
        ))}
      </div>
    </div> */}
    <div className="flex mx-10 md:mx-32 mt-16 ">
          <DiscoverMore/>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Academics