import { Slide } from '@mui/material'
import React, { useState ,useEffect} from 'react'
import {questions} from '../components/questions'
import AchievementBox from '../components/AchievementBox'
import Slider from '../components/Slider'
import i4 from '../assets/images/bh4.jpeg'
import logo from '../assets/images/logo.png'
import Footer from '../components/Footer'
const Achievements = () => {
  const [achievement, setAchievement] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAllAchievement = async () => {
    try {
      setLoading(true);

      // Check if achievements are already stored in session storage
      const storedAchievements = sessionStorage.getItem('achievements');
      if (storedAchievements) {
        setAchievement(JSON.parse(storedAchievements));
        setLoading(false);
        return;
      }

      const result = await fetch('https://api.bgsthimiri.com/achievement/');
      if (!result.ok) {
        throw new Error('Failed to fetch achievements');
      }
      const response = await result.json();
      setAchievement(response);

      // Store the fetched achievements to session storage
      sessionStorage.setItem('achievements', JSON.stringify(response));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllAchievement();
  }, []);
    const achQuick=[
        {
            title:"Achievements",
            link:'/achievements#achievement'
        }
    ]
  return (
    <div>
        <Slider images={[i4]} menuBackgroundImage={logo} interval={5000} quicklinks={achQuick} title={"ACHIEVEMENTS"}/>
        {loading ? (
        <div className="loading-overlay">
          <div className="loader"></div>
        </div>

    ) : (<>
        <div className="mx-5 w-[1150px] md:w-[95%]" id='achievement'>

        {achievement?.map(m1=>(
            <div className="my-10">
            <AchievementBox topic={m1?.topic} desc={m1?.desc} image={m1?.file} type={m1?.type} />
            </div>
        ))}
        </div>
        </>
    )}
    <Footer/>
    </div>
  )
}

export default Achievements